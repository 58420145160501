import React from "react";

import cuisinesModel from "./cuisines-model";
import EntityTable from "../entities/entity-table";

export default function CuisinesTable(props) {
  return (
    <EntityTable
      entityName="cuisines"
      entityModel={cuisinesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
