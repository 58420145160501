import React from "react";

import controlModesModel from "./control-modes-model";
import EntityTable from "../entities/entity-table";

export default function ControlModesTable(props) {
  return (
    <EntityTable
      entityName="controlModes"
      entityModel={controlModesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
