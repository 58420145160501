import React, { useEffect } from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

const ModifiedAutoCompleteWithOptionsObject = (props) => {
  const {
    control,
    name,
    options,
    multiple,
    parentIndex,
    ingredientsFromDB,
    isError,
    recipeIngredients,
    isLoopSection,
    selectIngredientHandler,
  } = props;
  const { setError, clearErrors, getValues, setValue } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option,
  });

  useEffect(() => {
    const ingredientId = getValues(
      `recipeIngredients.${parentIndex}.ingredientId`
    );
    const instructionId = getValues(
      `recipeIngredients.${parentIndex}.instructionId`
    );

    if (ingredientId && !instructionId) {
      setError(`recipeIngredients.${parentIndex}.instructionId`, {
        type: "manual",
        message: "Ingredient is not added in step",
      });
    } else {
      if (ingredientId && instructionId) {
        clearErrors(`recipeIngredients.${parentIndex}.ingredientId`);
        clearErrors(`recipeIngredients.${parentIndex}.instructionId`);
      }
    }
  }, [
    props.disabledItemsFocusable,
    getValues,
    parentIndex,
    setError,
    clearErrors,
  ]);

  const findPrepStyleDetails = (ingredientId, selectedPrepStyle) => {
    const allPrepStyles = getValues(
      `recipeIngredients.${parentIndex}.allPrepStyles`
    );

    if (allPrepStyles && selectedPrepStyle) {
      const matchingPrepStyle = allPrepStyles.additional?.find(
        (style) =>
          style.option?.toLowerCase() === selectedPrepStyle?.toLowerCase()
      );

      if (matchingPrepStyle) {
        return matchingPrepStyle;
      }
      if (
        allPrepStyles.default?.option?.toLowerCase() ===
        selectedPrepStyle?.toLowerCase()
      ) {
        return allPrepStyles.default;
      }
    }
    return null;
  };

  const handleAutocompleteChange = (evt, commonValue) => {
    if (name.split(".")[2] === "prepStyles") {
      const currentIngredientId = getValues(
        `recipeIngredients.${parentIndex}.ingredientId`
      );
      // Get the prep style object based on the selected prep style
      const prepStyleObj = findPrepStyleDetails(
        currentIngredientId,
        commonValue
      );

      if (prepStyleObj) {
        // Store the complete prep style object
        setValue(
          `recipeIngredients.${parentIndex}.prepStylesObj`,
          prepStyleObj
        );
      }

      // Store the selected prep style
      setValue(`recipeIngredients.${parentIndex}.prepStyles`, commonValue);
    }

    if (name.split(".")[2] === "name") {
      selectIngredientHandler(commonValue);
      const matchingIngredient = ingredientsFromDB?.find(
        (val) => val?.name?.toLowerCase() === commonValue?.toLowerCase()
      );

      if (matchingIngredient) {
        const selectedPrepStyle = matchingIngredient?.defaultPrepStyle?.option;

        // Store all prep styles first
        setValue(`recipeIngredients.${parentIndex}.allPrepStyles`, {
          default: matchingIngredient?.defaultPrepStyle,
          additional: matchingIngredient?.otherPrepStyles || [],
        });

        setValue(
          `recipeIngredients.${parentIndex}.prepStyles`,
          selectedPrepStyle || ""
        );

        setValue(
          `recipeIngredients.${parentIndex}.prepStylesObj`,
          matchingIngredient?.defaultPrepStyle || {}
        );

        setValue(
          `recipeIngredients.${parentIndex}.ingredientId`,
          matchingIngredient?.id
        );
        setValue(
          `recipeIngredients.${parentIndex}.ingredientImage`,
          matchingIngredient?.thumbnailUrl || matchingIngredient?.imageUrl
        );
        setValue(
          `recipeIngredients.${parentIndex}.ingredientType`,
          matchingIngredient?.category?.name
        );

        if (matchingIngredient?.defaultUnit) {
          setValue(
            `recipeIngredients.${parentIndex}.units`,
            matchingIngredient?.defaultUnit || ""
          );
          clearErrors(`recipeIngredients.${parentIndex}.units`);
        }

        if (isLoopSection) {
          setValue(
            `recipeIngredients.${parentIndex}.unitsPerStep`,
            recipeIngredients[parentIndex].units
          );
          clearErrors(`recipeIngredients.${parentIndex}.unitsPerStep`);
        }
      } else {
        setValue(`recipeIngredients.${parentIndex}.prepStyles`, "");
        setValue(`recipeIngredients.${parentIndex}.prepStylesObj`, {});
        setValue(`recipeIngredients.${parentIndex}.allPrepStyles`, {});
        setValue(`recipeIngredients.${parentIndex}.ingredientId`, "");
        setValue(`recipeIngredients.${parentIndex}.ingredientImage`, "");
        setValue(`recipeIngredients.${parentIndex}.ingredientType`, "");
        setValue(`recipeIngredients.${parentIndex}.units`, "");
        setValue(`recipeIngredients.${parentIndex}.quantity`, null);
        setValue(`recipeIngredients.${parentIndex}.quantityPerStep`, null);
        setValue(`recipeIngredients.${parentIndex}.unitsPerStep`, "");
      }
    }
    field.onChange(commonValue);
  };

  return (
    <Autocomplete
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          const inputValue = event.target.value;
          const isOption = options.some((option) => option === inputValue);
          if (!isOption) {
            event.defaultMuiPrevented = true;
          }
        }
      }}
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      sx={{
        border: isError && "1px solid red",
        borderRadius: isError && "4px",
        padding: isError && "4px",
        "&:hover": {
          "&::after": {
            content: props?.disabledItemsFocusable
              ? '"all ready used in some step"'
              : '""',
            color: props?.disabledItemsFocusable && "red",
          },
        },
      }}
      disablePortal
      disableUnderline
      disabledItemsFocusable={props.disabledItemsFocusable}
      disabled={props.disabled}
      label={field.name}
      id={field.name}
      onBlur={field.onBlur}
      onChange={handleAutocompleteChange}
      // onChange={field.onChange}
      autoHighlight={true}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={options}
      filterOptions={filterOptions}
      ListboxProps={{
        style: {
          maxHeight: "20vh",
          color: "#1C1C1C",
          fontFamily: "Mukta Mahee",
          fontSize: "14px",
          fontWeight: 500,
          background: "#F7F7F7",
          position: "bottom-start",
        },
        sx: {
          "& .MuiAutocomplete-option": {
            textTransform: "capitalize",
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          disableUnderline
          {...params}
          fullWidth
          variant={props.variant ? props.variant : "standard"}
          sx={{
            ".MuiInputBase-input": {
              fontFamily: "Mukta Mahee",
              fontSize: "16px",
              fontWeight: 400,
              color: "#393939",
              textTransform: "capitalize",
            },
          }}
          label={props.label}
          placeholder={props.placeholder ? props.placeholder : "select"}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default ModifiedAutoCompleteWithOptionsObject;
