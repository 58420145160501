import { createTheme } from "@mui/material";

const themes = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#EF4329",
      light: "#f26853",
      dark: "#a72e1c",
      background: "#f26853",
    },
    secondary: {
      main: "#292929",
      light: "#535353",
      dark: "#1c1c1c",
      background: "#fff",
    },
    blueInfo: {
      main: "#4C83FC",
      hover: "#6D9BFF",
      background: "#F3F7FF",
    },
    info: {
      main: "#ffffff",
    },
    success: {
      main: "#34C759",
      light: "#83b25c",
      dark: "#466f24",
      hover: "#5DD57B",
      background: "#DFF7E5",
    },
    grey: {
      main: "#8F8F8F",
      light: "#a5a5a5",
      dark: "#646464",
      background: "",
    },
    warning: {
      main: "#FFC148",
      light: "#ecab33",
      dark: "#a26900",
      hover: "#FFCF72",
      background: "#FDF0D9",
    },
    error: {
      main: "#B80000",
      hover: "#EF4D43",
      background: "#FFE1E4",
    },
    Background: {
      white: "#fff",
      seaShell: "#F8F5F2",
    },
    white: {
      main: "#f1f1f1",
    },
  },
  typography: {
    h1: {
      fontFamily: "Mukta Mahee",
      fontSize: "24px",
      fontWeight: 700,
      color: "#393939",
    },
    h2: {
      fontFamily: "Roboto Slab",
      fontSize: "20px",
      fontWeight: 500,
      color: "#393939",
    },
    h3: {
      fontFamily: "Mukta Mahee",
      fontSize: "20px",
      fontWeight: 700,
      color: "#393939",
    },
    h4: {
      fontFamily: "Roboto Slab",
      fontSize: "16px",
      fontWeight: 700,
      color: "#393939",
    },
    h5: {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",
      fontWeight: 700,
      color: "#393939",
    },
    h6: {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",
      fontWeight: 600,
      color: "#393939",
    },
    h7: {
      fontFamily: "Mukta Mahee",
      fontSize: "14px",
      fontWeight: 700,
      color: "#393939",
    },
    title1: {
      fontFamily: "Roboto Slab",
      fontSize: "32px",
      fontWeight: 700,
      color: "#393939",
    },
    title2: {
      fontFamily: "Roboto Slab",
      fontSize: "24px",
      fontWeight: 500,
      color: "#393939",
    },
    subtext1: {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",
      fontWeight: 500,
      color: "#393939",
    },
    subtext2: {
      fontFamily: "Mukta Mahee",
      fontSize: "14px",
      fontWeight: 500,
      color: "#393939",
    },
    body1: {
      fontFamily: "Mukta Mahee",
      fontSize: "24px",
      fontWeight: 400,
      color: "#393939",
    },
    body2: {
      fontFamily: "Mukta Mahee",
      fontSize: "20px",
      fontWeight: 400,
      color: "#393939",
    },
    body3: {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",
      fontWeight: 400,
      color: "#393939",
    },
    body4: {
      fontFamily: "Mukta Mahee",
      fontSize: "14px",
      fontWeight: 400,
      color: "#393939",
    },
    caption1: {
      fontFamily: "Mukta Mahee",
      fontSize: "12px",
      fontWeight: 600,
      color: "#393939",
    },
    caption2: {
      fontFamily: "Mukta Mahee",
      fontSize: "12px",
      fontWeight: 400,
      color: "#393939",
    },
  },
});
export default themes;
