/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import InfoIcon from "./assets/info-icon.png";

// Importing necessary components from Material UI
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

// Importing custom components
import ModifiedMentionsInput from "./modified-field/modified-mention";
import ModifiedTextField from "./modified-field/modified-text-field";
import CookingParameter from "./cooking-parameter";
import SingleImageUploader from "./components/image-uploader";
import { RecipeEntityContext } from "./hooks/entity-context";
import useRecipeInstruction from "./hooks/use-recipe-instruction";
import { actionHandler } from "./utils/action-handler";
import DeleteDerivedIngredientPopup from "./components/delete-derived-ingredient-popup";
import SingleVideoUploader from "./components/video-uploader";

// Main component for Recipe Instruction
export default function RecipeInstruction(props) {
  // Using form context for form handling
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const isError = errors?.recipeInstructions;
  const { cookingActions, cookingPresets } = useContext(RecipeEntityContext);

  // Destructuring props
  const { sectionId, instructionId, instructionArrayIndex, index, item } =
    props;

  // Watching for changes in recipeInstructions
  const recipeInstruction =
    getValues("recipeInstructions")[instructionArrayIndex];
  const cookingParameters = getValues("cookingParameters").find(
    (item) => item.instructionId === instructionId
  );
  const arrayCookingParametersIndex = getValues("cookingParameters").findIndex(
    (item) => item.id === cookingParameters?.id
  );
  const metaData = getValues("metadata");

  // State variables for handling mentions
  const [mentionTitleArray, setMentionTitleArray] = useState([]);

  const [selectedPreset, setSelectedPreset] = useState({});
  const recipeSections = getValues("recipeSections");
  const recipeSectionsIndex = recipeSections.findIndex(
    (item) => item.id === sectionId
  );
  const handleSelectedPreset = (args) => {
    setSelectedPreset(args);
  };

  const {
    recipeIngredientsBySectionId,
    setDerivedIngredient,
    setSelectSections,
    selectSections,
    derivedIngredientsArray,
    isUpdate,
    updateSaveHandler,
    updateRecipeDerivedIngredientWithSectionId,
    updateDerivedIngredientHandler,
    setSelectedIngredients,
    updateMentionTitleArray,
    setDisplayTitle,
    derivedIngredient,
    handleDelete,
    processString,
    isOpenDelete,
    setIsOpenDelete,
    handlePopupDelete,
  } = useRecipeInstruction({
    sectionId,
    instructionId,
    instructionArrayIndex,
    mentionTitleArray,
    handleSelectedPreset,
  });

  // Effect to update mention title array when it changes
  useEffect(() => {
    updateMentionTitleArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionTitleArray]);

  useEffect(() => {
    setSelectedPreset(selectedPreset);
  }, [selectedPreset]);

  useEffect(() => {
    const instructionAction = actionHandler(cookingParameters?.action);
    if (instructionAction) {
      const filteredAction = cookingActions.find(
        (item) => item.name === instructionAction
      );
      const filteredPresets = cookingPresets.find(
        (item) => item.actionId === filteredAction?.id
      );
      setSelectedPreset(filteredPresets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionArrayIndex]);

  // Rendering the component
  return (
    <Grid
      xs={12}
      container
      justifyContent="space-between"
      mt={2}
      key={`${item.id}${index}`}
    >
      <DeleteDerivedIngredientPopup
        isOpenDelete={isOpenDelete}
        setIsOpenDelete={setIsOpenDelete}
        handleDelete={handleDelete}
      />
      <Grid item xs={8} padding={1}>
        <Grid item xs={12}>
          <Typography mb={1} variant="subtext1" color="#1C1C1C">
            Step info <span style={{ color: "#EF4823" }}>*</span>
          </Typography>
          <ModifiedMentionsInput
            instructionId={instructionId}
            style={{
              border:
                isError && isError[instructionArrayIndex] && "1px solid red",
              borderRadius: isError && isError[instructionArrayIndex] && "4px",
            }}
            ingredients={recipeIngredientsBySectionId}
            metaData={metaData}
            // selectedActions={recipeInstruction?.selectedActions}
            // recipeInstruction={cookingParameters}
            setMentionTitleArray={setMentionTitleArray}
            selectedIngredients={recipeInstruction?.selectedIngredients}
            // setSelectedActions={setSelectedActions}
            // setCookingAction={setCookingAction}
            setSelectedIngredients={setSelectedIngredients}
            control={control}
            setDisplayTitle={setDisplayTitle}
            name={`recipeInstructions.${instructionArrayIndex}.mentionsTitle`}
          />
          <Box display="flex" justifyContent="space-between">
            {isError && (
              <Typography variant="caption1" color="#EF4823">
                {
                  errors?.recipeInstructions[instructionArrayIndex]
                    ?.mentionsTitle?.message
                }
              </Typography>
            )}
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <Typography variant="caption1" sx={{ textAlign: "end", mt: 1 }}>
                {metaData?.productReleases?.length > 0
                  ? `${recipeInstruction?.title?.length || 0} / 60`
                  : `${recipeInstruction?.title?.length || 0} / 160`}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <CookingParameter
            sectionId={sectionId}
            instructionId={instructionId}
            instructionArrayIndex={arrayCookingParametersIndex}
            cookingParameters={cookingParameters}
            cookingActions={cookingActions.filter(
              (item) => item.showInRecipeBuilder === true
            )}
            mentionTitleArray={mentionTitleArray}
            selectedPreset={selectedPreset}
            handleSelectedPreset={handleSelectedPreset}
          />
        </Grid>
        <Grid item container mt={2} xs={12} alignItems="center">
          <Grid item display="flex" flexDirection="column">
            <Typography variant="subtext1" mr={2}>
              Add Derived Ingredient{" "}
              <img
                src={InfoIcon}
                alt=""
                style={{ width: "16px", height: "16px" }}
              />
            </Typography>

            <TextField
              placeholder="Derived ingredient"
              control={control}
              variant="outlined"
              size="small"
              sx={{
                ".MuiInputBase-input": {
                  fontFamily: "Mukta Mahee",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#393939",
                },
              }}
              value={derivedIngredient}
              inputProps={{ maxLength: 30 }}
              onChange={(e) => {
                setDerivedIngredient(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} ml={3} display="flex" flexDirection="column">
            <Typography variant="subtext1">select section</Typography>
            {recipeSectionsIndex < recipeSections.length && (
              <Select
                size="small"
                id="outlined-select-currency"
                fullWidth
                sx={{
                  fontFamily: "Mukta Mahee",
                  fontSize: "14px",
                  fontWeight: 500,
                  position: "bottom-start",
                  width: "200px",
                  height: 40,
                }}
                value={selectSections}
                onChange={(e) => {
                  setSelectSections(e.target.value);
                }}
              >
                {recipeSections.map(
                  (sectionValue, sectionIndex) =>
                    recipeSectionsIndex <= sectionIndex && (
                      <MenuItem
                        size="small"
                        key={sectionIndex}
                        value={sectionValue.id}
                        sx={{
                          maxHeight: "20vh",
                          color: "#1C1C1C",
                          fontFamily: "Mukta Mahee",
                          fontSize: "14px",
                          fontWeight: 500,
                          background: "#F7F7F7",
                          position: "bottom-start",
                        }}
                      >
                        Section {sectionIndex + 1}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
          </Grid>
          <Grid item mt="24px">
            <Button
              disabled={derivedIngredient === "" || selectSections == null}
              variant="contained"
              sx={{
                height: "35px",
              }}
              onClick={
                isUpdate
                  ? updateSaveHandler
                  : updateRecipeDerivedIngredientWithSectionId
              }
            >
              <Typography
                variant="subtext1"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                  color: "#fff",
                }}
              >
                {isUpdate ? "update" : "add"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ maxHeight: "160px", overflow: "auto" }}>
          {derivedIngredientsArray?.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: "300px",
                  width: "80%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow sx={{ padding: "4px" }}>
                    <TableCell
                      sx={{ padding: "6px", border: "1px solid #ddd" }}
                    >
                      <Typography variant="subtext1" color="#1C1C1C">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ padding: "6px", border: "1px solid #ddd" }}
                    >
                      <Typography variant="subtext1" color="#1C1C1C">
                        Section
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ padding: "6px", border: "1px solid #ddd" }}
                    >
                      <Typography variant="subtext1" color="#1C1C1C">
                        Edit
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ padding: "6px", border: "1px solid #ddd" }}
                    >
                      <Typography variant="subtext1" color="#1C1C1C">
                        Delete
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {derivedIngredientsArray.map((ingredient, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: "1px solid #ddd",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ padding: "4px", border: "1px solid #ddd" }}
                      >
                        <Typography variant="subtext2" color="#1C1C1C">
                          {ingredient.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "4px",
                          border: "1px solid #ddd",
                          width: "180px",
                        }}
                      >
                        <Typography variant="subtext2" color="#1C1C1C">
                          {`section ${ingredient.section}`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "4px",
                          border: "1px solid #ddd",
                          width: "50px",
                        }}
                      >
                        <IconButton
                          sx={{
                            height: "35px",
                          }}
                          onClick={() =>
                            updateDerivedIngredientHandler(ingredient, index)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "4px",
                          border: "1px solid #ddd",
                          width: "50px",
                        }}
                      >
                        <IconButton
                          sx={{
                            height: "35px",
                            color: "#990000",
                          }}
                          onClick={() => handlePopupDelete(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Grid item xs={12} mt={4}>
          <FormControl fullWidth size="small">
            <ModifiedTextField
              placeholder="💡 an awesome tip from the creator"
              control={control}
              multiline
              variant="outlined"
              inputProps={{ maxLength: 250 }}
              rows={1}
              name={`recipeInstructions.${instructionArrayIndex}.tip`}
            />
            {isError && (
              <Typography variant="caption2" color="#EF4823">
                {
                  errors?.recipeInstructions[instructionArrayIndex]?.tip
                    ?.message
                }
              </Typography>
            )}
            <Typography variant="caption2" sx={{ textAlign: "end", mt: 1 }}>{`${
              recipeInstruction?.tip?.length || 0
            } / 250`}</Typography>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
        <Grid item>
          <Box mb={4}>
            <SingleImageUploader
              imageUrl={getValues(
                `recipeInstructions.${instructionArrayIndex}.stepImageUrl`
              )}
              multiple={false}
              storageLocation={`recipeInstructions/${instructionId}`}
              name={`recipeInstructions.${instructionArrayIndex}.stepImageUrl`}
              setFormValue={setValue}
              instructionArrayIndex={instructionArrayIndex}
              errorMessage={
                errors?.recipeInstructions?.[instructionArrayIndex]
                  ?.stepImageUrl?.message
              }
            />
          </Box>
          <Box>
            <SingleVideoUploader
              videoUrl={getValues(
                `recipeInstructions.${instructionArrayIndex}.stepVideoUrl`
              )}
              multiple={false}
              storageLocation={`recipeInstructions/${instructionId}`}
              name={`recipeInstructions.${instructionArrayIndex}.stepVideoUrl`}
              setFormValue={setValue}
              instructionArrayIndex={instructionArrayIndex}
              errorMessage={
                errors?.recipeInstructions?.[instructionArrayIndex]
                  ?.stepVideoUrl?.message
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
