import { Select } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";
function ModifiedSelectForPod(props) {
  const { name, control, multiple } = props;
  // console.log("props",props)

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <Select
      sx={{ ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" } }}
      size={props.size ? props.size : "small"}
      multiple={multiple}
      fullWidth
      labelId={name}
      id={name}
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={Array.isArray(field.value) ? field.value.sort() : []} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
    >
      {props.children}
    </Select>
  );
}
export default ModifiedSelectForPod;
