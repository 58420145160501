import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useController } from "react-hook-form";

const ControlledSelect = (props) => {
  const {
    control,
    name,
    options = [],
    multiple = false,
    label,
    size = "small",
    error,
    helperText,
    placeholder,
  } = props;

  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
    defaultValue: multiple ? [] : "",
  });

  const handleChange = (event) => {
    const { value } = event.target;
    field.onChange(value);
  };

  return (
    <FormControl fullWidth error={!!error || !!fieldError}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        {...field}
        onChange={handleChange}
        size={size}
        label={label}
        id={field.name}
        fullWidth
        multiple={multiple}
        value={field.value || (multiple ? [] : "")}
        displayEmpty
        renderValue={(selected) => {
          if (
            selected === "" ||
            (Array.isArray(selected) && selected.length === 0)
          ) {
            return <em>{placeholder}</em>;
          }
          return Array.isArray(selected)
            ? selected.map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(", ")
            : selected.charAt(0).toUpperCase() + selected.slice(1);
        }}
        style={{ textTransform: "capitalize" }}
      >
        {!multiple && (
          <MenuItem value="" disabled>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem
            key={`${option}-${index}`}
            value={option}
            style={{ textTransform: "capitalize" }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {(helperText || fieldError) && (
        <FormHelperText>{helperText || fieldError?.message}</FormHelperText>
      )}
    </FormControl>
  );
};

ControlledSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
};

export default ControlledSelect;
