/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AppBarComponent from "./appbar";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Button,
  IconButton,
  Box,
  ThemeProvider,
} from "@mui/material";
import Metadata from "./meta-data";
import RecipeSections from "./recipe-sections";
import Preview from "./preview";
import ReactJson from "react-json-view";
import StarIcon from "@mui/icons-material/Star";
import PreventBackModal from "./components/prevent-back-popup";
import ErrorIcon from "./assets/error.png";
import Toastify from "./components/custom-toast";
import useRecipeHook from "./use-recipe-hook";
import RecipeEntityProvider from "./hooks/entity-context";
import IsVerifiedByKlynkPopup from "./components/is-verified-by-klynk-popup";
import themes from "./theme/theme-config";
import {
  BottomBorder,
  SingleTabContainer,
  TabContainer,
} from "./components/index-page-styled";
import ErrorPopup from "./components/error.popup";

const RecipeForm = () => {
  const {
    methods,
    formValues,
    checkKeyDown,
    onSubmit,
    formState,
    isLoading,
    checkingInstructionError,
    formErrors,
    setIsLoading,
    isVerifiedByklynk,
    setIsVerifiedByklynk,
    handleConfirmForVerified,
    checkedMetaDataError,
    toastVisible,
    setToastVisible,
    setIsFormSubmit,
  } = useRecipeHook();

  const metaData = formValues.metadata;

  // store && typeof store.getStore === "function"
  //   ? useSelector((state) => state.user.userDetails)
  //   : useAuth();

  const [openConvertToProModel, setOpenConvertToProModel] = useState(false);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  //    The main tab to select between recipeInformation(metaData) and recipeSections and preview tabs
  const [currentFormView, setCurrentFormView] = useState("METADATA");

  const handleCurrentViewChange = (newValue, index) => {
    setCurrentFormView(newValue);
  };

  return (
    <ThemeProvider theme={themes}>
      <RecipeEntityProvider>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <Toastify />
            <ErrorPopup
              toastVisible={toastVisible}
              setToastVisible={setToastVisible}
              formErrors={formErrors}
            />
            <AppBarComponent
              setOpenBackModal={setOpenBackModal}
              editState={formState}
              setIsFormSubmit={setIsFormSubmit}
            />
            <PreventBackModal
              openBackModal={openBackModal}
              setOpenBackModal={setOpenBackModal}
              setIsLoading={setIsLoading}
              setIsFormSubmit={setIsFormSubmit}
            />
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <IsVerifiedByKlynkPopup
              isVerifiedByklynk={isVerifiedByklynk}
              setIsVerifiedByklynk={setIsVerifiedByklynk}
              handleConfirmForVerified={handleConfirmForVerified}
            />
            <Grid container spacing={2} style={styles.formRoot}>
              <Grid item xs={12}>
                <Grid display="flex" justifyContent="center" mb={2}>
                  <Typography variant="h1">
                    {metaData?.title || "Recipe Title"}
                  </Typography>
                  {metaData?.productReleases.length > 0 && (
                    <Box ml={1} sx={styles.proContainer}>
                      <StarIcon sx={{ color: "#EF4823" }} fontSize="17px" />
                      <Typography
                        variant="body4"
                        sx={{ color: "#EF4823", marginLeft: "3px" }}
                      >
                        PRO
                      </Typography>
                    </Box>
                  )}
                </Grid>

                <Grid container justifyContent="center">
                  <TabContainer item xs={8}>
                    <SingleTabContainer>
                      <Typography
                        onClick={() => handleCurrentViewChange("METADATA")}
                        variant="h3"
                        sx={
                          currentFormView === "METADATA"
                            ? {
                                color: "#EF4823",
                                cursor: "pointer",
                              }
                            : {
                                cursor: "pointer",
                                color: "#7C7B79",
                              }
                        }
                      >
                        {checkedMetaDataError &&
                          !formErrors?.metadata?.prepImage && (
                            <img src={ErrorIcon} alt="" />
                          )}{" "}
                        RECIPE INFORMATION
                      </Typography>
                      {currentFormView === "METADATA" && <BottomBorder />}
                    </SingleTabContainer>
                    <SingleTabContainer>
                      <Typography
                        onClick={() => handleCurrentViewChange("INSTRUCTIONS")}
                        variant="h3"
                        sx={
                          currentFormView === "INSTRUCTIONS"
                            ? {
                                color: "#EF4823",
                                cursor: "pointer",
                              }
                            : {
                                cursor: "pointer",
                                color: "#7C7B79",
                              }
                        }
                      >
                        {checkingInstructionError && (
                          <img src={ErrorIcon} alt="" />
                        )}
                        {"  "}
                        INGREDIENTS & INSTRUCTIONS
                      </Typography>
                      {currentFormView === "INSTRUCTIONS" && <BottomBorder />}
                    </SingleTabContainer>
                    <SingleTabContainer>
                      <Typography
                        onClick={() => {
                          handleCurrentViewChange("PREVIEW");
                          setOpenPreviewModal(true);
                        }}
                        variant="h3"
                        sx={
                          currentFormView === "PREVIEW"
                            ? {
                                color: "#EF4823",
                                cursor: "pointer",
                              }
                            : {
                                cursor: "pointer",
                                color: "#7C7B79",
                              }
                        }
                      >
                        PREVIEW RECIPE
                      </Typography>
                      {currentFormView === "PREVIEW" && <BottomBorder />}
                    </SingleTabContainer>
                  </TabContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ ...styles.tabContentContainer, position: "relative" }}
            >
              {currentFormView === "METADATA" && <Metadata />}
              {currentFormView === "INSTRUCTIONS" && <RecipeSections />}
              {currentFormView === "PREVIEW" && (
                <Preview
                  openPreviewModal={openPreviewModal}
                  setOpenPreviewModal={setOpenPreviewModal}
                  setCurrentFormView={setCurrentFormView}
                />
              )}
            </Grid>
          </form>
          {process.env.NODE_ENV !== "production" && (
            <ReactJson
              src={methods.watch()}
              theme="monokai"
              style={{ marginTop: 20 }}
              expand={false}
            />
          )}
        </FormProvider>
      </RecipeEntityProvider>
    </ThemeProvider>
  );
};

export default RecipeForm;

const styles = {
  formRoot: {
    display: "flex",
    marginTop: 90,
    width: "80vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tabsContainer: { width: "20vw", background: "#f5f5f5" },
  tabContentContainer: {
    display: "flex",
    width: "80vw",
    padding: 10,
    overflow: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  proContainer: {
    padding: "1px 9px",
    borderRadius: "20px",
    background: "#FFECE4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
