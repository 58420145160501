/* eslint-disable react-hooks/exhaustive-deps */
// Handles the user authentication and provides the user object to the rest of the app
// Uses localStorage to store the user object for persistence

import React from "react";
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import useEntity from "../pages/entities/use-entity";
import { recipeCards, userLogsCollection } from "../utils/constants";
export const globalDataContext = createContext();

const GlobalDataProvider = ({ children }) => {
  const {
    getEntities,
    realTimeRecipeCards,
    monthlyRecipeCards,
    weeklyRecipeCards,
  } = useEntity();
  const [recipeData, setRecipeData] = useState([]);
  const [monthWiseRecipes, setMonthWiseRecipes] = useState(0);
  const [weekWiseRecipes, setWeekWiseRecipes] = useState(0);
  const [monthWiseDeletedRecipes, setMonthWiseDeletedRecipes] = useState(0);
  const [weekWiseDeletedRecipes, setWeekWiseDeletedRecipes] = useState(0);
  const [ingredientsFromDb, setIngredientsFromDb] = useState([]);
  const [prepStyles, setPrepStyles] = useState([]);
  const [units, setUnits] = useState([]);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    monthlyRecipeCards(recipeCards, setMonthWiseRecipes);
    weeklyRecipeCards(recipeCards, setWeekWiseRecipes);
    monthlyRecipeCards(userLogsCollection, setMonthWiseDeletedRecipes);
    weeklyRecipeCards(userLogsCollection, setWeekWiseDeletedRecipes);
    realTimeRecipeCards(recipeCards, setRecipeData);
    getEntities("ingredients", setIngredientsFromDb);
    getEntities("prepStyles", setPrepStyles);
    getEntities("units", setUnits);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const value = useMemo(
    () => ({
      ingredientsFromDb,
      prepStyles,
      units,
      recipeData,
      monthWiseRecipes,
      weekWiseRecipes,
      isDelete,
      setIsDelete,
      monthWiseDeletedRecipes,
      weekWiseDeletedRecipes,
    }),
    [
      ingredientsFromDb,
      prepStyles,
      units,
      recipeData,
      monthWiseRecipes,
      weekWiseRecipes,
      isDelete,
      setIsDelete,
      monthWiseDeletedRecipes,
      weekWiseDeletedRecipes,
    ]
  );
  return (
    <globalDataContext.Provider value={value}>
      {children}
    </globalDataContext.Provider>
  );
};

export default GlobalDataProvider;

export const useGlobalData = () => {
  return useContext(globalDataContext);
};
