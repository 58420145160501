/* eslint-disable no-unused-vars */
import { useFormContext } from "react-hook-form";
import useRecipeHook from "../use-recipe-hook";
import { useState } from "react";

const useRecipeSection = () => {
  const { getValues, setValue } = useFormContext();
  const { generationOfNewUuid } = useRecipeHook();
  const recipeSections = getValues("recipeSections");

  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedInstructionId, _setSelectedInstructionId] = useState("");
  const [addSectionButton, setAddSectionButton] = useState(false);
  const [openDotMenu, setOpenDotMenu] = useState(false);

  // Function to handle addition of new section
  // Generates a new section with unique ID and default values
  const handleSectionAdd = () => {
    // setOpenFirstStep(false);
    setAddSectionButton(true);
    const newSectionId = generationOfNewUuid();
    const recipeId = getValues("metadata").recipeId || null;
    const newSection = {
      recipeId,
      id: newSectionId,
      sectionName: "",
      title: "",
      sectionType: "manual",
      sectionIndex: recipeSections?.length + 1 ?? 1,
      isLoopSection: false,
      loopInstruction: "",
      isProbeRequired: false,
    };
    setSelectedSectionId(newSectionId);
    setValue("recipeSections", [...recipeSections, newSection]);
  };

  const handleSectionChangeAfterDelete = (deletedSectionIndex) => {
    const newSelectedIndex =
      deletedSectionIndex === 0
        ? deletedSectionIndex + 1
        : deletedSectionIndex - 1;
    const newSelectedSection = recipeSections[newSelectedIndex];
    setSelectedSectionId(newSelectedSection.id);

    const recipeInstructions = getValues("recipeInstructions");
    const cookingParameters = getValues("cookingParameters");

    const newSelectedInstruction = recipeInstructions.find(
      (instruction) => instruction.sectionId === newSelectedSection.id
    );
    const newSelectedCookingParameter = cookingParameters.find(
      (parameter) => parameter.sectionId === newSelectedSection.id
    );

    _setSelectedInstructionId(newSelectedInstruction?.id);
  };

  // function to delete a recipe section
  const removeRecipeSection = (sectionId) => {
    setOpenDotMenu(false);
    const updatedValues = {};
    const sectionRelatedValues = [
      "recipeInstructions",
      "cookingParameters",
      "recipeIngredients",
    ];

    // Find the index of the section to be deleted
    const recipeSectionIndex = recipeSections.findIndex(
      (item) => item.id === sectionId
    );
    handleSectionChangeAfterDelete(recipeSectionIndex);

    // Filter out the deleted section and update section indices
    updatedValues["recipeSections"] = recipeSections
      .filter((section) => section.id !== sectionId)
      .map((item, index) => ({ ...item, sectionIndex: index + 1 }));

    // Filter out related items of the deleted section
    sectionRelatedValues.forEach((valueKey) => {
      updatedValues[valueKey] = getValues(valueKey).filter(
        (item) => item.sectionId !== sectionId
      );
    });

    // Set the updated values in the form
    Object.entries(updatedValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  return {
    selectedSectionId,
    selectedInstructionId,
    addSectionButton,
    openDotMenu,
    // useState
    setOpenDotMenu,
    setAddSectionButton,
    _setSelectedInstructionId,
    setSelectedSectionId,
    // functions
    handleSectionAdd,
    removeRecipeSection,
  };
};

export default useRecipeSection;
