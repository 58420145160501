/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import { nameValidation } from "../units/units-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";

const foodCategoriesModel = {
  name: "Food Categories",
  model: "foodCategories",
  route: "food-categories",
  collectionName: "foodCategories",
  defaultObject: {
    id: null,
    name: null,
    thumbnailUrl: null,
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 75,
      multiple: false,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 250,
      required: true,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span style={{ textTransform: "capitalize" }}>{name}</span>
          </div>
        );
      },
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Created At",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
      listLayoutWidth: 150,
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "date",
      label: "Last Updated",
      required: false,
      showInEditLayout: false,
      showInListLayout: false,
      listLayoutWidth: 150,
    },
  ],
  layout: [[{ name: "thumbnailUrl", width: 12 }], [{ name: "name", width: 6 }]],
};

export default foodCategoriesModel;
