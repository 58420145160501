export const actionHandler = (value) => {
  switch (value) {
    case "strain":
    case "mix":
    case "knead":
    case "rest":
    case "shape":
    case "drench":
    case "roll":
    case "flip":
    case "press":
    case "repeat":
    case "cool":
    case "pour":
    case "squeeze":
    case "set":
      return "";
    default:
      return value;
  }
};
