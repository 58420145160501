/* eslint-disable no-unused-vars */
// A login component which uses firebase google sign in and redirects to the home page
// Path: src/pages/Auth/Login.js

import React from "react";
import { useNavigate } from "react-router";
import { useAuth } from "./auth-context";
import { Grid, Button, Typography, Box, IconButton } from "@mui/material";
import klynkLogo from "../../assets/klynk-logo.png";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
export default function GoogleLogin() {
  const { signInWithGoogle } = useAuth();

  return (
    <Box>
      <Box style={style}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={klynkLogo} alt="klynk" />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <IconButton
              aria-label="google"
              color="primary"
              size="large"
              onClick={signInWithGoogle}
            >
              <ArrowCircleRightIcon style={{ fontSize: 50 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  padding: 10,
  transform: "translate(-50%, -50%)",
  width: 200,
  height: 200,
  background: "white",
  border: "1px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};
