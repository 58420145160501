// HOC for protected routes
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./auth-context";

export const RouteProtector = () => {
  const { user } = useContext(AuthContext);
  // console.log(user)
  return (
    // In production
    user ? <Outlet /> : <Navigate to="/login" />
    // In dev mode uncomment below line and comment above line
    // user ? <Outlet/> : <Outlet />
  );
};
