import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button, Box, Snackbar } from "@mui/material";
import { EmptyImageBox } from "../../rct-isolated-section/components/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { v4 as uuidv4 } from "uuid";
import fileUpload from "../../rct-isolated-section/assets/file-upload.png";
import { storage } from "../../../config/firebase-config";

export default function ImageUploader({
  name,
  value,
  register,
  setFormValue,
  storageLocation,
  error,
}) {
  const [image, setImage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [isImageUploadComplete, setIsImageUploadComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (typeof value === "string" && value !== "") {
      const image = {
        file: null,
        fileName: value,
        status: "UPLOADED",
        storageRef: null,
        downloadURL: value,
        description: "",
      };
      setImage(image);
    }
  }, [value]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 150 * 1024) {
      setErrorMessage("Image size should not exceed 150kb");
      setShowError(true);
      return;
    }

    const uuid = uuidv4();
    const newImage = {
      file,
      fileName: file.name,
      status: "CREATED",
      storageRef: storage
        .ref()
        .child(
          `${storageLocation}/original/${
            storageLocation.split("/")[1]
          }--${uuid}--${file.name}`
        ),
      downloadURL: URL.createObjectURL(file),
      description: "",
    };

    setImage(newImage);
    setIsImageUpdated(true);
    handleUpload(newImage);
  };

  const handleDelete = () => {
    setImage(null);
    setIsImageUpdated(true);
    setIsImageUploadComplete(true);
    setFormValue(name, "");
    if (fileInputRef.current) {
      fileInputRef.current.value = null; 
    }
  };

  const handleUpload = (newImage) => {
    if (newImage) {
      newImage.storageRef.put(newImage.file).on(
        "state_changed",
        (snapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          setErrorMessage("Upload failed. Please try again.");
          setShowError(true);
        },
        () => {
          newImage.storageRef.getDownloadURL().then((url) => {
            newImage.downloadURL = url;
            newImage.status = "UPLOADED";
            setUploadProgress(0);
            setImage(newImage);
            setIsImageUpdated(true);
            setIsImageUploadComplete(true);
            setFormValue(name, newImage.downloadURL);
          });
        }
      );
    }
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
                display: image ? "none" : "",
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <EmptyImageBox
                sx={{
                  border: error ? "2px dashed #D32F2F" : "2px dotted #A7A7A7",
                  width: "200px",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                <img src={fileUpload} height="16px" width="16px" alt="" />
                <Typography
                  my={1}
                  variant="subtext2"
                  sx={{ position: "relative" }}
                >
                  Choose Image to Upload
                  <span
                    style={{
                      color: "#D32F2F",
                      fontSize: "15px",
                      position: "absolute",
                      top: "0",
                      zIndex: 1,
                      lineHeight: "1",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Box textAlign="center">
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#7C7B79" }}
                  >
                    Supports JPG, JPEG, PNG
                  </Typography>
                  {error && (
                    <Typography
                      variant="body2"
                      sx={{ color: "#D32F2F", fontSize: "12px" }}
                    >
                      {error.message || "Image is required"}
                    </Typography>
                  )}
                </Box>
              </EmptyImageBox>
            </Grid>
            {image && (
              <Grid item xs={2}>
                <Grid container direction="column">
                  <Grid
                    item
                    style={{
                      borderRadius: 5,
                      border: "1px black solid",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={
                        image.downloadURL
                          ? image.downloadURL
                          : URL.createObjectURL(image.file)
                      }
                      alt="entityImage"
                      style={{
                        width: 150,
                        height: 150,
                        objectFit: "contain",
                      }}
                    />
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleDelete}
                    >
                      <DeleteForeverIcon />
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={3}
              style={{ display: uploadProgress > 0 ? "" : "none" }}
            >
              <Box>
                <Typography color="primary">{uploadProgress}%</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Snackbar for displaying error message */}
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={errorMessage}
      />
    </Box>
  );
}