/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// This is the model json that will used by the form generator to generate the form for the products CRUD
import React from "react";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const products = {
  name: "Products",
  model: "products",
  collectionName: "products",
  defaultObject: {
    id: null,
    productSku: null,
    name: null,
    version: null,
    description: null,
    productType: null,
  },
  fields: [
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text",
      label: "Name",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
    {
      name: "version",
      dataType: "String",
      inputFieldType: "text",
      label: "Version",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
    {
      name: "productType",
      dataType: "String",
      inputFieldType: "select",
      options: ["hardware", "software", "service"],
      label: "Product Type",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
    {
      name: "productSku",
      dataType: "String",
      inputFieldType: "text",
      label: "Product SKU",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
  ],
  layout: [
    [{ name: "productSku", width: 6 }],
    [{ name: "name", width: 6 }],
    [{ name: "version", width: 6 }],
    [{ name: "description", width: 12 }],
    [{ name: "productType", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "submit",
      color: "primary",
    },
  ],
};

export default products;
