/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { FirebaseApi } from "../../config/firebase-config";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Entities from "../entities/use-entity";
import AppContext from "../../config/app-context";

const DataGrids = styled(MuiDataGrid)({});

const UnitConversionsTable = (props) => {
  const AppState = useContext(AppContext);
  const { currentEntityData, setCurrentEntityData, setAddedEntity, close } =
    props;
  const [currentIngredient, setCurrentIngredient] = useState("");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentEntity = "conversions";
  const currentEntityCategory = "pure";
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsLength, setRowsLength] = useState([]);

  console.log(rowData);

  const column = [
    { field: "sourceUnit", headerName: "Source Unit", width: 250 },
    { field: "destinationunit", headerName: "Destination Unit", width: 250 },
    { field: "conversionFactor", headerName: "Conversion factor", width: 250 },
    { field: "unitType", headerName: "Unit type", width: 250 },
  ];

  const getData = async () => {
    setLoading(true);
    try {
      const data = await FirebaseApi["conversions"].get();
      const parsedData = data.docs.map((item) => {
        return {
          id: item.id,
          ...item.data(),
        };
        // return item
      });
      console.log(parsedData);
      setRowData(parsedData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const selectARow = (row) => {
    setCurrentIngredient(row?.row);
    console.log(row);
    AppState.handleCurrentEntityData(row?.row);
    AppState.handleCurrentEntityType(currentEntity);
    // setCurrentEntityData(row?.row)
  };

  const handleRowsSelected = (value) => {
    setRowsLength(value);
  };

  useEffect(() => {
    getData();
  }, []);

  // return loading ? (
  //     <div>Loading..</div>
  // ) : <>
  // <Entities />
  // <Box style={{ height: '90vh', width: '100%' }}>
  //     <DataGrids
  //         hideFooter
  //         checkboxSelection
  //         columns={column}
  //         rows={rowData}
  //         onRowClick={(details) => selectARow(details)}
  //     />
  // </Box>
  // </>
  return (
    <>
      <Entities
        Entity={currentEntity}
        currentEntityData={currentEntityData}
        currentEntityCategory={currentEntityCategory}
        rowsCount={rowsLength}
      />
      {loading ? (
        <div>Loading..</div>
      ) : (
        <Box style={{ height: "90vh", width: "100%" }}>
          <DataGrids
            hideFooter
            columns={column}
            rows={rowData}
            checkboxSelection
            onRowClick={(details) => selectARow(details)}
            onSelectionModelChange={(details) => handleRowsSelected(details)}
          />
        </Box>
      )}
    </>
  );
};

export default UnitConversionsTable;
