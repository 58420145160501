import React, { useRef } from "react";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useController } from "react-hook-form";
import { storage } from "../../../config/firebase-config";
import { v4 as uuidv4 } from "uuid";

const ControlledMultiMediaSelect = (props) => {
  const {
    control,
    name,
    options = [],
    label,
    size = "small",
    error,
    helperText,
    storageLocation,
  } = props;

  const {
    field: { value: fieldValue, onChange },
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  // Ensure value is always an array
  const value = Array.isArray(fieldValue) ? fieldValue : [];
  const fileInputRef = useRef(null);

  const handleMediaUpload = async (event, selectedOption) => {
    const file = event.target.files[0];
    if (!file || !selectedOption) return;

    try {
      const uuid = uuidv4();
      const fileName = `${storageLocation}--${uuid}--${file.name}`;
      const storageRef = storage
        .ref()
        .child(`${storageLocation}/original/${fileName}`);
      const snapshot = await storageRef.put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();

      const fileType = file.type.startsWith("video/") ? "video" : "image";

      const newMediaItem = {
        mediaUrl: downloadURL,
        option: selectedOption,
        fileType: fileType,
      };

      const existingIndex = value.findIndex(
        (item) => item.option === selectedOption
      );
      if (existingIndex !== -1) {
        const updatedValue = [...value];
        updatedValue[existingIndex] = newMediaItem;
        onChange(updatedValue);
      } else {
        onChange([...value, newMediaItem]);
      }
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  const handleRemoveItem = async (itemToRemove) => {
    try {
      if (itemToRemove.mediaUrl) {
        const urlParts = itemToRemove.mediaUrl.split("/");
        const fileNameWithParams = urlParts[urlParts.length - 1];
        const fileName = fileNameWithParams.split("?")[0];

        const fullPath = `${storageLocation}/original/${decodeURIComponent(
          fileName
        )}`;

        try {
          const storageRef = storage.ref(fullPath);
          await storageRef.delete();
        } catch (storageError) {
          console.error("Error deleting from storage:", storageError);
        }
      }

      const newValue = value.filter(
        (item) => item.option !== itemToRemove.option
      );
      onChange(newValue);
    } catch (error) {
      console.error("Error in handleRemoveItem:", error);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOption) return;
    if (!value.find((item) => item.option === selectedOption)) {
      const newMediaItem = {
        option: selectedOption,
      };
      onChange([...value, newMediaItem]);
    }
  };

  const renderMediaPreview = (media) => {
    if (!media || !media.mediaUrl) {
      return null;
    }

    if (media.fileType === "video") {
      return (
        <video
          src={media.mediaUrl}
          controls
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      );
    }
    return (
      <img
        src={media.mediaUrl}
        alt={media.option}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    );
  };

  const isOptionSelected = (option) => {
    return value.some((item) => item.option === option);
  };

  const allOptions = options.filter((option) => !isOptionSelected(option));

  return (
    <Box sx={{ width: "100%", "& > *": { mb: 2 } }}>
      <FormControl fullWidth error={!!error || !!fieldError}>
        {label && <FormLabel>{label}</FormLabel>}

        <Select
          value=""
          onChange={(e) => handleOptionSelect(e.target.value)}
          displayEmpty
          size={size}
          sx={{ mb: 2 }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                textTransform: "capitalize",
                ...(isOptionSelected(option) && {
                  backgroundColor: "rgba(239, 67, 41, 0.08)",
                  "&:hover": {
                    backgroundColor: "rgba(239, 67, 41, 0.08);",
                  },
                }),
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: 2,
          }}
        >
          {value.map((media) => (
            <Box
              key={media.option}
              sx={{
                position: "relative",
                p: 2,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
              }}
            >
              {media && renderMediaPreview(media)}
              <Typography
                variant="body2"
                sx={{
                  textTransform: "capitalize",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {media.option}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  display: "flex",
                  gap: 1,
                }}
              >
                <Button
                  component="label"
                  variant="outlined"
                  size="small"
                  startIcon={<UploadIcon />}
                >
                  {media.mediaUrl ? "Replace" : "Upload"}
                  <input
                    type="file"
                    accept="image/*,video/*"
                    hidden
                    onChange={(e) => handleMediaUpload(e, media.option)}
                  />
                </Button>
                <IconButton
                  size="small"
                  onClick={() => handleRemoveItem(media)}
                  color="error"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
        {(helperText || fieldError) && (
          <FormHelperText>{helperText || fieldError.message}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ControlledMultiMediaSelect;
