import React from "react";

import presetsModel from "./presets-model";
import EntityTable from "../entities/entity-table";
import PresetsCRUDForm from "./presets-crud-form";
export default function PresetsTable(props) {
  return (
    <EntityTable
      entityName="cookingPresets"
      entityModel={presetsModel}
      canEdit={true}
      canDelete={true}
      customCRUD={true}
      customFormComponent={PresetsCRUDForm}
    />
  );
}
