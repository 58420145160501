import { Box, Grid, styled } from "@mui/material";

export const BottomBorderPrepBox = styled(Box)({
  marginTop: "5px",
  width: 50,
  height: "4px",
  background: "#EF4823",
  borderRadius: "5px 5px 0px 0px",
});
export const TabContainerPrepGrid = styled(Grid)({
  minWidth: "450px",
  display: "flex",
  // height: "40px",
  maxWidth: "80%",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});
export const SingleTabContainerPrepBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  minWidth: "150px",
});
