import React from "react";

import tagsModel from "./tags-model";
import EntityTable from "../entities/entity-table";

export default function TagsTable(props) {
  return (
    <EntityTable
      entityName="tags"
      entityModel={tagsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
