import React from "react";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

export const synonymsValidation = string()
  .nullable(true)
  .matches(/^[a-zA-Z\s,]*$/, {
    message:
      "No special characters and numbers are allowed, and names should be separated by commas.",
    excludeEmptyString: true,
  })
  .max(40, "Maximum 40 characters are allowed");

export const nameValidation = string()
  .required("Required")
  .matches(/^[a-zA-Z\s]+$/, {
    message: "No special characters and numbers are allowed",
    excludeEmptyString: true,
  })
  .max(40, "Maximum 40 characters are allowed");

const units = {
  name: "Units",
  model: "units",
  collectionName: "units",
  defaultObject: {
    id: "",
    name: "",
    symbol: "",
    synonyms: "",
    quantity: "",
    // isDerived: true,
    isVerified: true,
    system: "",
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span style={{ textTransform: "capitalize" }}>{name}</span>
          </div>
        );
      },
    },
    {
      name: "symbol",
      dataType: "String",
      inputFieldType: "noChar-text",
      label: "Symbol",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: string().required("Symbol is required"),
    },
    {
      name: "synonyms",
      dataType: "String",
      inputFieldType: "synonyms-text-modified",
      label: "Synonyms",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
      validation: synonymsValidation,
      listLayoutComponent: (props) => {
        const synonyms = props.row?.synonyms;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{synonyms}</span>
          </div>
        );
      },
    },
    {
      name: "quantity",
      dataType: "String",
      inputFieldType: "noChar-noValid-text",
      label: "Quantity",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: false,
    },
    // {
    //   name: "isDerived",
    //   dataType: "Boolean",
    //   inputFieldType: "switch",
    //   label: "Is Derived?",
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    //   showInListLayout: true,
    //   listLayoutWidth: 150,
    //   required: true,
    //   validation: string().required("Is Derived? is required"),
    // },
    {
      name: "isVerified",
      dataType: "Boolean",
      inputFieldType: "switch",
      label: "Is Verified?",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      checked: true,
      validation: boolean().required("Is Verified? is required"),
    },
    {
      name: "system",
      dataType: "String",
      inputFieldType: "select",
      options: ["SI", "Imperial", "General"],
      label: "System",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: string().required("System is required"),
      placeholder: "Select the system"
    },
  ],
  layout: [
    [{ name: "name", width: 6 }],
    [
      { name: "symbol", width: 6 },
      { name: "synonyms", width: 6 },
    ],
    [
      { name: "quantity", width: 6 },
      // { name: "isDerived", width: 6 },
    ],
    [
      { name: "isVerified", width: 6 },
    ],
    [{ name: "system", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default units;