/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { FirebaseApi, firestore } from "../../config/firebase-config";
import { deletedRecipes, userLogsCollection } from "../../utils/constants";
import Logger from "../../utils/logger/logger";
import { useAuth } from "../auth/auth-context";

export default function useEntity() {
  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const entityArrayName = [
    "recipeCards",
    "recipeIngredients",
    "recipeSections",
    "recipeInstructions",
    "cookingParameters",
  ];

  // List of entities
  const getEntities = async (entityName, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName].get();
      // const data = await FirebaseApi[entityName]
      //   .orderBy("updatedAt", "desc")
      //   .get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt:
          //   item.data().createdAt &&
          //   item.data().createdAt.hasOwnProperty("toDate")
          //     ? item.data().createdAt.toDate()
          //     : "",
        };
      });
      localSetter(parsedData);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      Logger.error("useEntity: getEntities: error", error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  const realTimeRecipeCards = async (entityName, localSetter) => {
    try {
      const docRef = FirebaseApi[entityName];
      docRef.onSnapshot((querySnapshot) => {
        const recipeCardList = [];
        querySnapshot.forEach((doc) => {
          recipeCardList.push(doc.data());
        });
        localSetter(recipeCardList);
      });
    } catch (error) {
      console.error("Error fetching real-time recipe cards:", error);
    }
  };

  const monthlyRecipeCards = async (entityName, localSetter) => {
    try {
      const startOfMonth = new Date();
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      const endOfMonth = new Date(startOfMonth);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);
      endOfMonth.setHours(23, 59, 59, 999);

      const startOfMonthTimestamp =
        firebase.firestore.Timestamp.fromDate(startOfMonth);
      const endOfMonthTimestamp =
        firebase.firestore.Timestamp.fromDate(endOfMonth);

      let query = FirebaseApi[entityName]
        .where("createdAt", ">=", startOfMonthTimestamp)
        .where("createdAt", "<=", endOfMonthTimestamp);

      if (entityName === userLogsCollection) {
        query = query
          .where("module", "==", "recipes")
          .where("operationType", "==", "delete");
      }

      query.onSnapshot((querySnapshot) => {
        const recipeCount = querySnapshot.size;
        localSetter(recipeCount);
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  const weeklyRecipeCards = async (entityName, localSetter) => {
    try {
      const startOfWeek = new Date();
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      startOfWeek.setHours(0, 0, 0, 0);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);

      const startOfWeekTimestamp =
        firebase.firestore.Timestamp.fromDate(startOfWeek);
      const endOfWeekTimestamp =
        firebase.firestore.Timestamp.fromDate(endOfWeek);

      let query = FirebaseApi[entityName]
        .where("createdAt", ">=", startOfWeekTimestamp)
        .where("createdAt", "<=", endOfWeekTimestamp);

      if (entityName === userLogsCollection) {
        query = query
          .where("module", "==", "recipes")
          .where("operationType", "==", "delete");
      }

      query.onSnapshot((querySnapshot) => {
        const recipeCount = querySnapshot.size;
        localSetter(recipeCount);
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  // List of entities
  const getEntitiesAsArray = async (entityName) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName].get();

      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      setLoading(false);
      setLoadingMessage("Done!");
      return parsedData;
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
      return [];
    }
  };

  // Get a single entity by id
  const getEntity = async (entityName, entityId, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const doc = await FirebaseApi[entityName].doc(entityId).get();
      let docData = doc.data();
      let data = {
        ...docData,
        id: doc.id,
        // updatedAt:
        //   docData && docData.updatedAt ? docData.updatedAt.toDate() : "",
        // createdAt:
        //   docData && docData.createdAt ? docData.createdAt.toDate() : "",
      };
      localSetter(data);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Get a single entity by id
  const getEntityByKey = async (entityName, key, keyValue, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName]
        .where(key, "==", keyValue)
        .get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      if (parsedData.length > 0) {
        localSetter(parsedData[0]);
      } else {
        localSetter({});
      }
      // localSetter(parsedData)
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Get a multiple entity by a specific key
  const getEntitiesByKey = async (entityName, key, keyValue, localSetter) => {
    setLoading(true);
    setLoadingMessage("Loading... " + entityName + "...");
    try {
      const data = await FirebaseApi[entityName]
        .where(key, "==", keyValue)
        .get();
      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
          // id: item.id,
          // updatedAt: item.data().updatedAt
          //   ? item.data().updatedAt.toDate()
          //   : "",
          // createdAt: item.data().createdAt
          //   ? item.data().createdAt.toDate()
          //   : "",
        };
      });
      localSetter(parsedData);
      setLoadingMessage("Done!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error in loading data!");
      setLoading(false);
    }
  };

  // Save a new entity
  const addEntity = async (entityName, entityData, localSetter) => {
    setLoading(true);
    setLoadingMessage("Saving... " + entityName + "...");
    try {
      const timestamp = new Date().toISOString();
      // entityData["createdAt"] = timestamp;
      entityData["updatedAt"] = timestamp;
      // If entityData.id is not provided, create a new document
      const docRef = entityData.id
        ? FirebaseApi[entityName].doc(entityData.id)
        : FirebaseApi[entityName].doc();

      await docRef.set(entityData);

      localSetter({
        ...entityData,
        id: docRef.id,
        updatedAt: entityData.updatedAt ? entityData.updatedAt : "",
        createdAt: entityData.createdAt ? entityData.createdAt : "",
      });

      setLoadingMessage("Done! refresh to see changes");
    } catch (error) {
      console.error("Error adding entity:", error);
      setLoadingMessage("Error!");
    } finally {
      setLoading(false);
    }
  };

  // Update an entity
  const updateEntity = async (entityName, entityData) => {
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + " : " + entityData.id);
    try {
      entityData["updatedAt"] = new Date().toISOString();
      await FirebaseApi[entityName].doc(entityData.id).update(entityData);

      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // Update an entity by sender Id
  const updateEntityBySenderId = async (entityName, entityData, senderId) => {
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + "...");
    try {
      entityData["updatedAt"] = new Date().toISOString();
      await FirebaseApi[entityName].doc(senderId).set(entityData);
      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // add an entity by sender Id
  const addEntityBySenderId = async (entityName, entityData, senderId) => {
    setLoading(true);
    setLoadingMessage("Updating... " + entityName + "...");
    try {
      entityData["updatedAt"] = new Date().toISOString();
      const data = await FirebaseApi[entityName].doc(senderId).set(entityData);
      setLoadingMessage("Done! refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not updated!");
      setLoading(false);
    }
  };

  // Delete an entity
  const deleteEntity = async (entityName, entityId) => {
    setLoading(true);
    setLoadingMessage("Deleting... " + entityName + "...");
    try {
      setLoadingMessage("Deleting... " + entityName + "...");
      const data = await FirebaseApi[entityName].doc(entityId).delete();
      setLoadingMessage("Done! .. refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not deleted!");
      setLoading(false);
    }
  };
  // Delete an entity in collections.
  const deleteEntityInDifferentCollection = async (entityId) => {
    setLoading(true);
    setLoadingMessage("Deleting recipe...");
    try {
      setLoadingMessage("Deleting recipes...");
      const data = await FirebaseApi[entityArrayName[0]].doc(entityId).delete();
      const data1 = await FirebaseApi[entityArrayName[1]].where(
        "recipeId",
        "==",
        entityId
      );
      data1.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data2 = await FirebaseApi[entityArrayName[2]].where(
        "recipeId",
        "==",
        entityId
      );
      data2.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data3 = await FirebaseApi[entityArrayName[3]].where(
        "recipeId",
        "==",
        entityId
      );
      data3.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      const data4 = await FirebaseApi[entityArrayName[4]].where(
        "recipeId",
        "==",
        entityId
      );
      data4.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      setLoadingMessage("Done! .. refresh to see changes");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoadingMessage("Error not deleted!");
      setLoading(false);
    }
  };

  const deleteMultipleRecipes = async (entityIds) => {
    entityIds.forEach((Ids) => {
      setLoadingMessage("Deleting....");
      deleteEntityInDifferentCollection(Ids);
    });
  };

  // Delete multiple entities
  const deleteEntities = async (entityName, entityIds) => {
    setLoading(true);
    entityIds.forEach((element) => {
      setLoadingMessage("Deleting... " + entityName + element + "...");
      deleteEntity(entityName, element);
    });
    setLoadingMessage("Done! refresh to see changes");
    setLoading(false);
  };

  const entityMappingToDifferentCollections = (
    entityName,
    entityData,
    status
  ) => {
    if (status) {
      entityData.forEach((entityDataItem) => {
        updateEntityBySenderId(entityName, entityDataItem, entityDataItem.id);
      });
    } else {
      entityData.forEach((entityDataItem) => {
        addEntityBySenderId(entityName, entityDataItem, entityDataItem.id);
      });
    }
  };

  const entityByDifferentCollections = (entityName, data, status) => {
    if (status) {
      Object.keys(data).map((item) => {
        if (item === "metadata") {
          updateEntityBySenderId(
            entityName[0],
            {
              recipeIngredientNames: data["recipeIngredientNames"],
              recipeIngredientsLength: data["recipeIngredientsLength"],
              ...data[item],
              id: data["reicpeId"],
              isRecipeStatus: data["isRecipeStatus"],
              isRecipeProSelected: data["isRecipeProSelected"],
              isRikuSelected: data["isRikuSelected"],
              isSemiSelected: data["isSemiSelected"],
            },
            data.recipeId
          );
        } else if (
          item !== "metadata" &&
          item !== "recipeId" &&
          item !== "author"
        ) {
          if (item === "recipeIngredients") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "recipeSections") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "recipeInstructions") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "cookingParameters") {
            entityMappingToDifferentCollections(item, data[item], status);
          }
        }
      });
    } else {
      Object.keys(data).map((item) => {
        if (item === "metadata") {
          addEntityBySenderId(
            entityName[0],
            {
              recipeIngredientNames: data["recipeIngredientNames"],
              recipeIngredientsLength: data["recipeIngredientsLength"],
              ...data[item],
              id: data["reicpeId"],
              isRecipeStatus: data["isRecipeStatus"],
              isRecipeProSelected: data["isRecipeProSelected"],
              isRikuSelected: data["isRikuSelected"],
              isSemiSelected: data["isSemiSelected"],
            },
            data.recipeId
          );
        } else if (
          item !== "metadata" &&
          item !== "recipeId" &&
          item !== "author"
        ) {
          if (item === "recipeIngredients") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "recipeSections") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "recipeInstructions") {
            entityMappingToDifferentCollections(item, data[item], status);
          } else if (item === "cookingParameters") {
            entityMappingToDifferentCollections(item, data[item], status);
          }
        }
      });
    }
  };
  const addUserLogsHandler = async () => {
    try {
      const userLogs = {
        userId: user?.userId || "",
        userName: user?.displayName || "",
        createdAt: "",
        operationType: "delete",
        module: "recipes",
        diiferenceOfRecord: "",
        newRecord: "",
      };

      await FirebaseApi[userLogsCollection].add(userLogs);
    } catch (error) {
      console.error("Error writing user log: ", error);
    }
  };

  return {
    loading,
    loadingMessage,
    getEntities,
    getEntitiesByKey,
    getEntityByKey,
    getEntitiesAsArray,
    getEntity,
    addEntity,
    updateEntity,
    deleteEntityInDifferentCollection,
    deleteEntity,
    deleteEntities,
    entityByDifferentCollections,
    realTimeRecipeCards,
    deleteMultipleRecipes,
    addUserLogsHandler,
    monthlyRecipeCards,
    weeklyRecipeCards,
  };
}
