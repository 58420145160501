import { useNavigate, useRoutes } from "react-router";
import { privateRoutes } from "../../config/private-routes";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(window.location.pathname, { replace: true });
  }, [navigate]);
  const routes = useRoutes(privateRoutes);
  return routes;
};
export default PrivateRoutes;
