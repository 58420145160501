import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography, Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { userLogsCollection } from "../../utils/constants";
import { columns } from "./user-logs-columns";
import { useLogsHook } from "./use-logs-hook";
import ReactJson from "react-json-view";

// Styles moved to useStyles hook
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "50%",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "50%",
      boxSizing: "border-box",
    },
  },
  drawerContent: {
    padding: theme.spacing(4),
    width: "80%",
  },
  container: {
    height: "80vh",
    width: "100%",
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
}));

const UserLogs = () => {
  const { getEntities } = useLogsHook();
  const [rowData, setRowData] = useState([]);
  const [diffrenceInValue, setDiffrenceInValue] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  const refreshData = async () => {
    await getEntities(userLogsCollection, setRowData);
  };

  const onSelectChange = (value) => {
    const selectedRow = rowData.find((item) => item.id === value[0]);
    let parsedDifferenceData = {};
    if (selectedRow?.diiferenceOfRecord) {
      parsedDifferenceData = JSON.parse(selectedRow?.diiferenceOfRecord);
    } else if (selectedRow?.newRecord) {
      parsedDifferenceData = JSON.parse(selectedRow?.newRecord);
    }
    setDiffrenceInValue(parsedDifferenceData);
    setDrawerOpen(true);
  };

  useEffect(() => {
    refreshData();
    return () => setRowData([]);
  }, []);

  return (
    <Box padding={2}>
      <Grid container className={classes.header}>
        <Grid item xs={3}>
          <Typography variant="h5">User Logs</Typography>
        </Grid>
      </Grid>
      <Box className={classes.container}>
        <DataGrid
          rows={rowData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableMultipleRowSelection={true}
          onSelectionModelChange={onSelectChange}
        />
      </Box>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        className={classes.drawer}
      >
        <Box className={classes.drawerContent}>
          <Typography variant="h6">Logs Information</Typography>
          <ReactJson src={diffrenceInValue} theme="monokai" />
        </Box>
      </Drawer>
    </Box>
  );
};

export default UserLogs;
