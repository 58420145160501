// 404 Error Page
// TODO: Report error to error tracking service
import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Error404() {
  const error = useRouteError();
  const navigate = useNavigate()
  console.error(error);
  return (
    <Box padding={4} id="error-page">
        <Grid container spacing={2} alignItems={'center'} justify={'center'}>
            <Grid item  justifyItems="center" alignItems={"center"}>
                <img height={400} width={400} src="https://i.imgur.com/qIufhof.png" alt="404 Error" />
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1" color="initial">
                        404
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="initial">
                            Even the best of us make mistakes.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="initial">
                            We can't seem to find the page you're looking for.
                            <i>{error.statusText || error.message}</i>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="text" color="primary" onClick={()=> navigate('/')}>
                            <ArrowBackIcon />
                            Home Page
                        </Button>
                    </Grid>                    
                </Grid>
            </Grid>
        </Grid>     
    </Box>
  );
}