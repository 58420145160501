/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import PrepSection from "./prep-section"; // Importing PrepSection component
import ServeSection from "./serve-section"; // Importing ServeSection component
import CookSection from "./cook-section"; // Importing CookSection component
import RecipeInstructions from "./recipe-instructions"; // Importing RecipeInstructions component
import RecipeIngredients from "./recipe-ingredients"; // Importing RecipeIngredients component
import { Box, Button, Divider, Grid, Typography } from "@mui/material"; // Importing Button and ButtonGroup from material-ui
import DeleteIcon from "./assets/delete-icon.png";
import DeleteSectionModal from "./components/delete-section-popup";
import ClearIcon from "@mui/icons-material/Clear";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useRecipeSection from "./hooks/use-recipe-section";
import ErrorIcon from "./assets/error.png";
import {
  BottomBorderPrepBox,
  SingleTabContainerPrepBox,
  TabContainerPrepGrid,
} from "./components/ingredients-instructions-styles";
import { BottomBorder } from "./components/index-page-styled";

// Main component for handling recipe sections
export default function RecipeSections() {
  // State variables for selected tab, selected section ID and selected instruction ID
  const [selectedTab, setSelectedTab] = useState("PREP");
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext(); // useFormContext hook for form values
  const [openDeleteSectionPopup, setOpenDeleteSectionPopup] = useState(false);
  const {
    selectedSectionId,
    selectedInstructionId,
    addSectionButton,
    openDotMenu,
    setOpenDotMenu,
    setAddSectionButton,
    _setSelectedInstructionId,
    setSelectedSectionId,
    handleSectionAdd,
    removeRecipeSection,
  } = useRecipeSection();

  // Fetching recipe sections from form values
  const recipeSections = getValues("recipeSections");

  // Function to handle section change
  // Sets selected tab to "COOK" and updates selected section ID
  const handleSectionChange = (newValue) => {
    setOpenDotMenu(false);
    // setOpenFirstStep(true);
    setSelectedTab("COOK");
    setSelectedSectionId(newValue);
    // Also de-select prep and serve
  };

  // Function to set selected instruction ID
  const setSelectedInstructionId = (args) => {
    _setSelectedInstructionId(args);
  };

  // Rendering component
  return (
    <Box width="99%">
      <Divider />
      {/* Button group for selecting recipe sections */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          // height: 65,
          background: "#fff",
        }}
      >
        <TabContainerPrepGrid mt={3} item xs={8}>
          <SingleTabContainerPrepBox>
            <Typography
              onClick={() => {
                setSelectedTab("PREP");
                setOpenDotMenu(false);
              }}
              variant="h6"
              sx={
                selectedTab == "PREP"
                  ? {
                      color: "#EF4823",
                      cursor: "pointer",
                    }
                  : {
                      cursor: "pointer",
                      color: "#7C7B79",
                    }
              }
            >
              PREP INGREDIENTS
            </Typography>
            {selectedTab == "PREP" && <BottomBorderPrepBox />}
          </SingleTabContainerPrepBox>
          {recipeSections &&
            recipeSections?.map((section, index) => (
              <SingleTabContainerPrepBox key={index} sx={{ minWidth: "120px" }}>
                <Typography
                  onClick={() => handleSectionChange(section.id)}
                  variant="h6"
                  sx={
                    section.id === selectedSectionId && selectedTab == "COOK"
                      ? {
                          color: "#EF4823",
                          cursor: "pointer",
                        }
                      : {
                          cursor: "pointer",
                          color: "#7C7B79",
                        }
                  }
                >
                  {errors?.recipeSections && errors?.recipeSections[index] && (
                    <img
                      src={ErrorIcon}
                      alt=""
                      style={{ height: 14, marginRight: "4px" }}
                    />
                  )}{" "}
                  SECTION{" "}
                  {`${index + 1}` <= 9 ? `0${index + 1}` : `${index + 1}`}
                </Typography>
                {section.id === selectedSectionId && selectedTab == "COOK" && (
                  <BottomBorderPrepBox />
                )}
              </SingleTabContainerPrepBox>
            ))}
          <SingleTabContainerPrepBox ml={2} sx={{ minWidth: "160px" }}>
            <Typography
              onClick={() => {
                setSelectedTab("SERVE");
                setOpenDotMenu(false);
              }}
              variant="h6"
              sx={
                selectedTab == "SERVE"
                  ? {
                      color: "#EF4823",
                      cursor: "pointer",
                    }
                  : {
                      cursor: "pointer",
                      color: "#7C7B79",
                    }
              }
            >
              {errors?.metadata && errors?.metadata?.recipeServeDescription && (
                <img
                  src={ErrorIcon}
                  alt=""
                  style={{ height: 14, marginRight: "4px" }}
                />
              )}
              SERVE INSTRUCTIONS
            </Typography>
            {selectedTab == "SERVE" && <BottomBorderPrepBox />}
          </SingleTabContainerPrepBox>
        </TabContainerPrepGrid>
        {selectedTab == "COOK" && (
          <Grid display="flex" alignItems="end">
            <Button
              variant="outlined"
              sx={{
                height: "35px",
                marginTop: "16px",
                opacity: openDotMenu && 0.5,
                border: "2px solid #EF4823",
              }}
              onClick={() => {
                handleSectionAdd();
                setOpenDotMenu(false);
              }}
            >
              <Typography
                variant="body2"
                color="primary"
                sx={{ textTransform: "capitalize", fontSize: "14px" }}
              >
                Add section
              </Typography>
            </Button>
            {recipeSections?.length > 1 && (
              <Box
                mt={2}
                ml={2}
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenDotMenu(!openDotMenu)}
              >
                {openDotMenu ? <ClearIcon /> : <MoreVertIcon />}
              </Box>
            )}
          </Grid>
        )}
        {openDotMenu && (
          <Box
            sx={{
              width: "155px",
              height: "40px",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
              background: "#fff",
              position: "absolute",
              right: "0px",
              top: 60,
              borderRadius: "6px",
            }}
          >
            <Grid
              display="flex"
              padding="8px"
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenDeleteSectionPopup(true)}
            >
              <img src={DeleteIcon} alt="" />
              <Typography variant="body4" color="primary" ml={1}>
                Delete section
              </Typography>
            </Grid>
          </Box>
        )}
      </Box>

      <DeleteSectionModal
        openDeleteSectionPopup={openDeleteSectionPopup}
        setOpenDeleteSectionPopup={setOpenDeleteSectionPopup}
        removeRecipeSection={removeRecipeSection}
        selectedSectionId={selectedSectionId}
        setOpenDotMenu={setOpenDotMenu}
      />
      {/* Container for displaying selected section */}
      <div style={{ paddingTop: 40 }}>
        {/* Display "PREP" section if selected */}
        {selectedTab === "PREP" && <PrepSection />}
        {/* Display "COOK" section if selected */}
        {selectedTab === "COOK" && (
          <>
            {/* Mapping through recipe sections and displaying the selected one */}
            {recipeSections.map((section, sectionIndex) => (
              <>
                <CookSection
                  key={section.id}
                  sectionId={section.id}
                  sectionIndex={sectionIndex}
                  section={section}
                  isSelected={section.id === selectedSectionId}
                />
              </>
            ))}
            {/* Display recipe ingredients for the selected section */}
            <RecipeIngredients
              selectedInstructionId={selectedInstructionId}
              sectionId={selectedSectionId}
              setAddSectionButton={setAddSectionButton}
              addSectionButton={addSectionButton}
            />
            {/* Display recipe instructions for the selected section */}
            <RecipeInstructions
              setSelectedInstructionId={setSelectedInstructionId}
              selectedInstructionId={selectedInstructionId}
              sectionId={selectedSectionId}
              setAddSectionButton={setAddSectionButton}
              addSectionButton={addSectionButton}
            />
          </>
        )}
        {/* Display "SERVE" section if selected */}
        {selectedTab === "SERVE" && <ServeSection />}
      </div>
    </Box>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  height: 285,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 0px",
};
