import React, { useContext, useState } from "react";
import useRecipeHook from "../use-recipe-hook";
import { Grid, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { RecipeEntityContext } from "./entity-context";
import { useFormContext } from "react-hook-form";

const useRecipeIngredients = (props) => {
  const { setValue, getValues, setAddSectionButton, setOpenMoreOptions } =
    props;
  const { generationOfNewUuid } = useRecipeHook();
  const { ingredients: ingredientsFromDb } = useContext(RecipeEntityContext);
  const [ingredientUnits, setIngredientsUnits] = useState([]);
  const [ingredientPrepStyles, setIngredientsPrepStyles] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const { clearErrors } = useFormContext();

  // getting values from "form"
  const recipeIngredients = getValues("recipeIngredients");
  const recipeInstructions = getValues("recipeInstructions");

  const addRecipeIngredient = (sectionId) => {
    const newIngredient = {
      id: generationOfNewUuid(),
      ingredientId: "",
      isScaleIngredient: false,
      name: "",
      quantity: null,
      quantityPerStep: null,
      ingredient: "",
      ingredientType: "",
      ingredientImage: "",
      prepStyles: "",
      units: "",
      unitsPerStep: "",
      schedulingLimit: "",
      isOutput: false,
      isRecipeGenerated: false,
      isUserGenerated: false,
      loadingPosition: "",
      podType: "",
      podPosition: [],
      categoryInContextOfRiku: "",
      sectionId,
      recipeId: getValues("metadata").recipeId || "",
      instructionId: props.selectedInstructionId || "",
    };
    setValue("recipeIngredients", [...recipeIngredients, newIngredient]);
    setAddSectionButton(false);
  };

  const handleDeleteDerived = (ingredient, sectionId) => {
    if (ingredient?.isOutput) {
      const updatedInstructions = recipeInstructions.map((instruction) => {
        const filterInstructions = instruction?.derivedIngredients?.filter(
          (item) =>
            item.sectionId !== sectionId && item.name !== ingredient.name
        );
        return {
          ...instruction,
          derivedIngredients: filterInstructions,
        };
      });
      setValue("recipeInstructions", updatedInstructions);
    }
  };

  const handleUndo = (deletedIngredientObject, updatedRecipeIngredients) => {
    setValue("recipeIngredients", [
      ...updatedRecipeIngredients,
      deletedIngredientObject,
    ]);
    setOpenMoreOptions(false);
  };
  const removeTaggedIngredient = (ingredient, sectionId) => {
    const deletedIngredientObject = recipeIngredients.find(
      (item) => item.id === ingredient?.id
    );
    const updatedRecipeIngredients = recipeIngredients.filter(
      (item) => item.id !== ingredient?.id
    );

    setValue("recipeIngredients", updatedRecipeIngredients);
    clearErrors(
      `recipeIngredients.${recipeIngredients.findIndex(
        (ing) => ing.id === ingredient.id
      )}`
    );
    toast(
      (t) => (
        <Grid
          container
          sx={{
            maxWidth: "38vw",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography
              variant="body2"
              sx={{ color: "#fff", fontWeight: 700, fontSize: "14px" }}
            >
              {deletedIngredientObject?.name
                ? `${deletedIngredientObject?.name?.toUpperCase()} DELETED!`
                : "Empty row deleted"}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#A7A7A7", fontWeight: 700, fontSize: "14px" }}
            >
              {
                "The ingredient was deleted. If you wish to recover, please press undo."
              }
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleUndo(deletedIngredientObject, updatedRecipeIngredients);
              toast.dismiss(t.id);
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "#FF8469", fontWeight: 700, fontSize: "14px" }}
            >
              {"UNDO"}
            </Typography>
          </Grid>
        </Grid>
      ),
      {
        duration: 5000,
      }
    );
    setTimeout(() => {
      handleDeleteDerived(ingredient, sectionId);
    }, 5000);
  };

  const removeRecipeIngredient = (ingredient, sectionId) => {
    const deletedIngredientObject = recipeIngredients.find(
      (item) => item.id === ingredient?.id
    );
    if (deletedIngredientObject?.instructionId) {
      setOpenDeletePopup(true);
    } else {
      removeTaggedIngredient(ingredient, sectionId);
    }
  };
  const selectIngredientHandler = (ingredient) => {
    const matchingIngredient = ingredientsFromDb?.find(
      (val) => val?.name?.toLowerCase() === ingredient?.toLowerCase()
    );
    setIngredientsUnits([
      ...new Set([
        matchingIngredient?.defaultUnit,
        ...(matchingIngredient?.otherUnits || []),
      ]),
    ]);
    setIngredientsPrepStyles([
      ...new Set([
        matchingIngredient?.defaultPrepStyle,
        ...(matchingIngredient?.otherPrepStyles || []),
      ]),
    ]);
  };

  return {
    addRecipeIngredient,
    removeRecipeIngredient,
    selectIngredientHandler,
    ingredientUnits,
    ingredientPrepStyles,
    openDeletePopup,
    setOpenDeletePopup,
    removeTaggedIngredient,
  };
};

export default useRecipeIngredients;
