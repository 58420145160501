import { FirebaseApi } from "../../config/firebase-config";

export const useLogsHook = () => {
  function formatTimestamp({ seconds }) {
    const date = new Date(seconds * 1000);
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    })
      .format(date)
      .replace(",", "");
  }

  const getEntities = async (entityName, localSetter) => {
    try {
      const data = await FirebaseApi[entityName]
        .orderBy("createdAt", "desc")
        .get();

      const parsedData = data.docs.map((item) => {
        return {
          ...item.data(),
        };
      });

      localSetter(parsedData);
    } catch (error) {
      console.error("error", error);
    }
  };
  return {
    formatTimestamp,
    getEntities,
  };
};
