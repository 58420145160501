import React from "react";

import creatorLeadsModel from "./creator-leads-model";
import EntityTable from "../entities/entity-table";

export default function CreatorLeadsTable(props) {
  return (
    <EntityTable
      entityName="creatorLeads"
      entityModel={creatorLeadsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
