import React from "react";

import foodCategoriesModel from "./food-categories-model";
import EntityTable from "../entities/entity-table";

export default function FoodCategoriesTable(props) {
  return (
    <EntityTable
      entityName="foodCategories"
      entityModel={foodCategoriesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
