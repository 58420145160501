/* eslint-disable no-unused-expressions */
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import useRecipeHook from "../use-recipe-hook";
import {
  selectionPreset,
  settingManualHeatLevel,
} from "../modified-field/modified-for-cooking-actions";
import { RecipeEntityContext } from "./entity-context";

const useRecipeInstruction = (props) => {
  const {
    sectionId,
    instructionId,
    instructionArrayIndex,
    mentionTitleArray,
    handleSelectedPreset,
  } = props;
  const { getValues, setValue, clearErrors, setError } = useFormContext();
  const { generationOfNewUuid } = useRecipeHook();
  const { cookingPresets, cookingActions, modeSets } =
    useContext(RecipeEntityContext);
  const [mentionGlobalTitleArray, setMentionGlobalTitleArray] = useState([]);
  const [selectCookingAction, setSelectCookingAction] = useState("");
  const [instructionIds, setInstructionIds] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [derivedIngredient, setDerivedIngredient] = useState("");
  const [selectSections, setSelectSections] = useState(null);
  const [updateIndex, setUpdateIndex] = useState(null);
  const [derivedIngredientsArray, setDerivedIngredientsArray] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const recipeIngredients = getValues("recipeIngredients");
  const recipeSections = getValues("recipeSections");
  const cookingParameters = getValues("cookingParameters");
  const recipeInstructions = getValues("recipeInstructions");
  const filterSectionIndex = recipeSections.findIndex(
    (item) => item.id === selectSections
  );
  const [getHeatLevel, setGetHeatLevel] = useState(
    cookingParameters[instructionArrayIndex]?.manualHeatLevel || "low"
  );

  const selectHeatLevelHandler = (heatLevel) => {
    setGetHeatLevel(heatLevel);
    setValue(
      `cookingParameters.${instructionArrayIndex}.manualHeatLevel`,
      heatLevel
    );
  };

  // const tagIngredient = recipeIngredients
  //   .filter((item) => item.instructionId === instructionId)
  //   .map((ingredient) => ingredient.name);
  // console.log("tagingredinet", tagIngredient, instructionId);
  // useEffect(() => {
  //   setTaggedIngredient(tagIngredient);
  // }, [sectionId]);

  // Fetch initial derived ingredients from the form values
  useEffect(() => {
    const initialDerivedIngredients =
      getValues(
        `recipeInstructions.${instructionArrayIndex}.derivedIngredients`
      ) || [];
    setDerivedIngredientsArray(initialDerivedIngredients);
  }, [sectionId, recipeIngredients, recipeInstructions]);
  // useEffect(() => {
  //   if (selectCookingAction === "add" && mentionTitleArray?.length === 0) {
  //     setError(`recipeInstructions.${instructionArrayIndex}.mentionsTitle`, {
  //       type: "required",
  //       message: "min one ingredient is required for add step ",
  //     });
  //   } else {
  //     clearErrors(`recipeInstructions.${instructionArrayIndex}.mentionsTitle`);
  //   }
  // }, [mentionTitleArray, selectCookingAction]);

  const recipeIngredientsBySectionId = recipeIngredients?.filter(
    (ingredient) => ingredient?.sectionId === sectionId
  );

  const updateRecipeDerivedIngredientWithSectionId = () => {
    const derivedIngredientObject = {
      name: derivedIngredient,
      section: filterSectionIndex + 1,
      sectionId: selectSections,
    };
    const updatedDerivedIngredientsArray = [
      ...derivedIngredientsArray,
      derivedIngredientObject,
    ];

    setDerivedIngredientsArray(updatedDerivedIngredientsArray);
    setDerivedIngredient("");
    setSelectSections(null);
    const newIngredient = {
      id: generationOfNewUuid(),
      ingredientId: "",
      sectionId: selectSections,
      recipeId: getValues("metadata").recipeId || "",
      instructionId: "",
      name: derivedIngredient,
      isOutput: true,
    };
    setValue("recipeIngredients", [...recipeIngredients, newIngredient]);
    setValue(
      `recipeInstructions.${instructionArrayIndex}.derivedIngredients`,
      updatedDerivedIngredientsArray
    );
  };
  const handlePopupDelete = (index) => {
    setIsOpenDelete(true);
    setDeleteIndex(index);
  };

  const handleDelete = () => {
    const updatedArray = derivedIngredientsArray.filter(
      (_, i) => i !== deleteIndex
    );
    setDerivedIngredientsArray(updatedArray);
    setValue(
      `recipeInstructions.${instructionArrayIndex}.derivedIngredients`,
      updatedArray
    );
    // Find the ingredient to delete from recipeIngredients
    const ingredientToDelete = derivedIngredientsArray[deleteIndex];
    const updatedRecipeIngredients = recipeIngredients.filter(
      (ingredient) => ingredient.name !== ingredientToDelete.name
    );

    // Update the form values
    setValue("recipeIngredients", updatedRecipeIngredients);
    setIsOpenDelete(false);
  };
  const updateDerivedIngredientHandler = (newDerivedIngredient, index) => {
    setUpdateIndex(index);
    setIsUpdate(true);
    setDerivedIngredient(newDerivedIngredient.name);
    setSelectSections(newDerivedIngredient.sectionId);
  };
  const updateSaveHandler = () => {
    const updatedArray = derivedIngredientsArray.map((item, i) =>
      i === updateIndex
        ? {
            ...item,
            name: derivedIngredient,
            sectionId: selectSections,
            section: filterSectionIndex + 1,
          }
        : item
    );
    setDerivedIngredientsArray(updatedArray);
    setValue(
      `recipeInstructions.${instructionArrayIndex}.derivedIngredients`,
      updatedArray
    );
    setDerivedIngredient("");
    setSelectSections(null);
    setIsUpdate(false);
    // Update the corresponding ingredient in recipeIngredients
    const ingredientToUpdate = derivedIngredientsArray[updateIndex];
    const updatedRecipeIngredients = recipeIngredients.map((ingredient) =>
      ingredient.name === ingredientToUpdate.name
        ? { ...ingredient, name: derivedIngredient, sectionId: selectSections }
        : ingredient
    );
    setValue("recipeIngredients", updatedRecipeIngredients);
  };
  // Function for setting cooking action as string (single value)
  const setCookingAction = (cookingAction) => {
    setSelectCookingAction(cookingAction);
    const foundAction = cookingActions.find(
      (action) => action.name === cookingAction
    );
    const foundPreset = cookingPresets?.find(
      (preset) => preset.actionId === foundAction?.id
    );
    const foundModeSet = modeSets?.find(
      (item) => item.id === foundPreset?.controlModeId
    );

    handleSelectedPreset(foundPreset);

    setValue(
      `cookingParameters.${instructionArrayIndex}.action`,
      foundAction?.name
    );
    setValue(
      `recipeInstructions.${instructionArrayIndex}.action`,
      foundAction?.name
    );

    selectionPreset(
      foundPreset,
      foundModeSet,
      setValue,
      getValues,
      instructionArrayIndex,
      cookingAction,
      clearErrors,
      sectionId
    );

    const manualHeatLevel = settingManualHeatLevel(foundPreset || {});
    setValue(
      `cookingParameters.${instructionArrayIndex}.manualHeatLevel`,
      manualHeatLevel
    );
  };

  const setSelectedActions = (actions) => {
    // setValue(
    //   `recipeInstructions.${instructionArrayIndex}.selectedActions`,
    //   actions
    // );
    // setValue(`recipeInstructions.${instructionArrayIndex}.actions`, actions);
    // console.log(actions);
  };

  const setSelectedIngredients = (ingredients, instructionId) => {
    // setValue(
    //   `recipeInstructions.${instructionArrayIndex}.selectedIngredients`,
    //   ingredients
    // );
    setInstructionIds(instructionId);
  };

  const updateMentionTitleArray = () => {
    const processIngredients = (ingredientsArray) => {
      return ingredientsArray.flatMap((selectedIngredient) => {
        const [selectedName, selectedQuantity, selectedUnits] =
          selectedIngredient.split(",");

        return recipeIngredientsBySectionId.filter(
          (ingredient) =>
            ingredient.name === selectedName &&
            (!selectedQuantity ||
              String(ingredient.quantity) === selectedQuantity) &&
            (!selectedUnits || ingredient.units === selectedUnits)
        );
      });
    };

    const updateRecipeIngredients = (filteredIngredients, instructionId) => {
      filteredIngredients.forEach((item) => {
        const filteredIngredientsIndex = recipeIngredients?.indexOf(item);
        const newIngredient = {
          ...item,
          instructionId: instructionId,
        };
        setValue(
          `recipeIngredients.${filteredIngredientsIndex}`,
          newIngredient
        );
      });
    };

    if (mentionTitleArray?.length > mentionGlobalTitleArray.length) {
      const filteredIngredients = processIngredients(mentionTitleArray);
      updateRecipeIngredients(filteredIngredients, instructionIds);

      setMentionGlobalTitleArray(mentionTitleArray);
    } else if (mentionTitleArray?.length < mentionGlobalTitleArray.length) {
      const filteredIngredientsGlobal = processIngredients(
        mentionGlobalTitleArray
      );
      const filteredIngredients = processIngredients(mentionTitleArray);
      filteredIngredientsGlobal.forEach((globalItem) => {
        if (
          !filteredIngredients.some(
            (localItem) =>
              localItem.name === globalItem.name &&
              localItem.quantity === globalItem.quantity
          )
        ) {
          const filteredIngredientsIndex =
            recipeIngredients?.indexOf(globalItem);
          setValue(`recipeIngredients.${filteredIngredientsIndex}`, {
            ...globalItem,
            instructionId: "",
          });
        }
      });
      setMentionGlobalTitleArray(mentionTitleArray);
    }
  };

  const setDisplayTitle = (title) => {
    setValue(`recipeInstructions.${instructionArrayIndex}.title`, title);
  };

  useEffect(() => {}, [selectedPreset]);
  const processString = (input) => {
    // Remove @, square brackets, and text inside square brackets
    if (input) {
      let cleanedString = input?.replace(/@\[[^\]]*\]/g, "")?.trim();
      return cleanedString;
    }

    // Return the cleaned string
  };

  return {
    selectedPreset,
    recipeIngredientsBySectionId,
    derivedIngredientsArray,
    selectSections,
    isUpdate,
    updateSaveHandler,
    setDerivedIngredient,
    updateDerivedIngredientHandler,
    setSelectSections,
    updateRecipeDerivedIngredientWithSectionId,
    setCookingAction,
    setSelectedActions,
    setSelectedIngredients,
    updateMentionTitleArray,
    setDisplayTitle,
    derivedIngredient,
    handleDelete,
    processString,
    getHeatLevel,
    selectHeatLevelHandler,
    isOpenDelete,
    setIsOpenDelete,
    handlePopupDelete,
  };
};

export default useRecipeInstruction;
