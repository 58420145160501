import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";
import GoogleLogin from "./pages/auth/google-login";
import { RouteProtector } from "./pages/auth/route-protector";
import PrivateRoutes from "./pages/auth/private-routes";
import AuthProvider from "./pages/auth/auth-context";
import { ThemeProvider } from "@mui/material";
import theme from "./config/theme";
import GlobalDataProvider from "./config/global-data-context";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <GlobalDataProvider>
            <Routes>
              <Route path="/login" element={<GoogleLogin />} />
              <Route path="*" element={<RouteProtector />}>
                <Route path="*" element={<PrivateRoutes />} />
              </Route>
            </Routes>
          </GlobalDataProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
