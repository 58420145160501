import { Box, styled } from "@mui/material";

export const ImageUploaderContainerGrid = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 30px",
  borderRadius: "8px",
});
export const ImageBox = styled(Box)({
  width: "250px",
  height: "250px",
  position: "relative",
  "&:hover": {
    ".delete-icon": {
      display: "block",
    },
    ".replace-icon": {
      display: "flex",
    },
  },
});
export const DeleteIconBox = styled(Box)({
  position: "absolute",
  right: "10px",
  top: "10px",
  background: "#F1F1F1",
  borderRadius: "50%",
  height: 30,
  width: 30,
  cursor: "pointer",
  display: "none",
  opacity: 0.5,
});

export const ReplaceIconBox = styled(Box)({
  position: "absolute",
  left: "10px",
  bottom: "10px",
  background: "#F1F1F1",
  borderRadius: "45px",
  cursor: "pointer",
  display: "none",
  opacity: 0.5,
  padding: "5px 8px",
});
export const EmptyImageBox = styled(Box)({
  borderRadius: "8px",
  width: "250px",
  height: "250px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});
