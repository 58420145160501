/* eslint-disable no-unused-vars */
import React from "react";

// Variable Entities
// import Ingredients from '../pages/Ingredients/ingredients';
// import IngredientStates from '../pages/IngredientStates/ingredientStates';
// import IngredientCategories from '../pages/IngredientCategories/ingredientCategories';

// Constant Entities

// import CookingMethods from '../pages/CookingMethods/cookingMethods';
// import MeasurementUnits from '../pages/MeasurementUnits/measurementUnits';
// import ManualRCT from '../pages/Recipes/manualRCT';

// Navigation
import AppSideNavigator from "../components/app-side-navigator";

// Errors
import Error404 from "../components/error-404.js";

// Home
import Dashboard from "../pages/dashboard/dashboard.js";

// Recipes
// View
import RecipesTable from "../pages/recipes/recipes-table.js";
import RecipeInstructionsTable from "../pages/recipes/recipe-instructions-table.js";
import ViewRecipes from "../pages/recipes/view-recipes.js";
// Create
// import CreateRecipePro from "../pages/recipes/create-recipe-pro-old.js";
// import CreateRecipeUsingChatGPT from "../pages/recipes/create-recipe-using-chat-gpt-old.js.js";
// import CreateRecipeByMobileDocEditor from "../pages/recipes/create-recipe-mobile-doc-old.js";
// import CreateRecipeRedo from "../pages/recipes/create-recipe-redo-old.js";

// Ingredients
import IngredientsTable from "../pages/ingredients/ingredients-table";
import FoodCategoriesTable from "../pages/food-categories/food-categories-table";

// Filters
import AislesTable from "../pages/aisles/aisles-table";
import DietsTable from "../pages/diets/diets-table";
import CuisinesTable from "../pages/cuisines/cuisines-table";
import AllergiesTable from "../pages/allergies/allergies-table";
import MedicalConditionsTable from "../pages/medical-conditions/medical-conditions-table";
import TagsTable from "../pages/tags/tags-table";

import ToolsTable from "../pages/tools/tools-table";
// import ToolsCategoriesEntityTable from "../pages/ToolCategories/toolCategoriesTable";

// Cooking
import ActionsTable from "../pages/actions/actions-table";
import PrepStylesTable from "../pages/prep-styles/prep-styles-table";
import UnitsTable from "../pages/units/units-table";

import PresetsTable from "../pages/presets/presets-table";
import UnitConversionsTable from "../pages/units-conversion/unit-conversions-table.js";
import CoursesTable from "../pages/courses/courses-table";
import ControlModesTable from "../pages/control-modes/control-modes-table";

// Deprecated
// import CookingCategoriesTable from "../pages/CookingCategories/CookingCategoriesTable";
//

// Appliances

// import IngredientTypeTable from "../pages/IngredientType/IngredientTypeTable";
// import IngredientsTable from '../pages/Recipes/CreateRecipe/IngredientsSection.js/IngredientsTable';

// import FormGenerator from "../pages/Enitities/formGenerator";
// Klynk Specific

// Common
import ProductReleasesTable from "../pages/products/product-releases-table";
import ProductsTable from "../pages/products/products-table";

// App
import RecipeFiltersTable from "../pages/recipe-filters/recipe-filters-table";
import RecipeFilterGroupsTable from "../pages/recipe-filter-groups/recipe-filter-groups-table";
// Platform
import CreatorLeadsTable from "../pages/creator-leads/creator-leads-table";

// Semi
import SemiTest from "../pages/semi-websockets-test";

// Riku
import QRTable from "../pages/riku-qr-codes/qr-table.js";

import IsolatedRct from "../pages/rct-isolated-section";
import UserLogs from "../pages/user-logs/user-logs";

export const privateRoutes = [
  {
    path: "/",
    element: <AppSideNavigator />,
    errorElement: <Error404 />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "ingredients", element: <IngredientsTable /> },
      { path: "food-categories", element: <FoodCategoriesTable /> },
      { path: "diets", element: <DietsTable /> },
      { path: "allergies", element: <AllergiesTable /> },
      { path: "medical-conditions", element: <MedicalConditionsTable /> },
      { path: "cuisines", element: <CuisinesTable /> },
      { path: "actions", element: <ActionsTable /> },
      { path: "prep-styles", element: <PrepStylesTable /> },
      { path: "courses", element: <CoursesTable /> },
      { path: "units", element: <UnitsTable /> },
      { path: "unit-conversions", element: <UnitConversionsTable /> },
      { path: "tools", element: <ToolsTable /> },
      { path: "tags", element: <TagsTable /> },
      { path: "presets", element: <PresetsTable /> },
      { path: "recipes", element: <RecipesTable /> },
      { path: "recipe-instructions", element: <RecipeInstructionsTable /> },
      { path: "recipes/:recipeId", element: <ViewRecipes /> },
      // { path: "create-recipe-pro", element: <CreateRecipePro /> },
      // { path: "create-recipe-redo", element: <CreateRecipeRedo /> },
      // {
      //   path: "create-recipe-mobiledoc",
      //   element: <CreateRecipeByMobileDocEditor />,
      // },
      // { path: "create-recipe-ai", element: <CreateRecipeUsingChatGPT /> },
      // { path: "create-recipe-pro/:recipeId", element: <CreateRecipePro /> },
      { path: "product-releases", element: <ProductReleasesTable /> },
      { path: "products", element: <ProductsTable /> },
      { path: "semi-test", element: <SemiTest /> },
      { path: "creator-leads", element: <CreatorLeadsTable /> },
      { path: "aisles", element: <AislesTable /> },
      { path: "recipe-filters", element: <RecipeFiltersTable /> },
      { path: "recipe-filter-groups", element: <RecipeFilterGroupsTable /> },
      { path: "qr-code-table", element: <QRTable /> },
      { path: "control-modes", element: <ControlModesTable /> },
      { path: "user-logs", element: <UserLogs /> },
    ],
  },
  {
    path: "/new-recipe-creation",
    element: <IsolatedRct />,
  },
  {
    path: "/new-recipe-creation/:recipeId",
    element: <IsolatedRct />,
  },
];
