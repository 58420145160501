import React, { useEffect } from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import PopupImage from "../assets/caution-icon.png";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import useRecipeHook from "../use-recipe-hook";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  height: 250,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 0px",
};

export default function PreventBackModal(props) {
  const navigate = useNavigate();
  const { submitRecipe } = useRecipeHook();
  const { openBackModal, setOpenBackModal, setIsLoading, setIsFormSubmit } =
    props;
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const data = getValues();
  const metaData = getValues("metadata");

  const onExitAndSave = async () => {
    try {
      setIsLoading(true);
      setOpenBackModal(false);
      if (metaData.isDraftRecipe) {
        setValue("metadata.isDraftRecipe", true);
        await submitRecipe(data);
      } else {
        setValue("metadata.isDraftRecipe", false);
        await submitRecipe(data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred during submission:", error);
    }
  };

  return (
    <Modal
      open={openBackModal}
      onClose={() => setOpenBackModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          xs={12}
          container
          flexDirection="column"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item>
            <img src={PopupImage} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontSize: "20px" }}>
              Unsaved changes found!
            </Typography>
          </Grid>
          <Grid item ml="20px">
            <Typography variant="body3" sx={{ lineHeight: "22px" }}>
              You have unsaved changes. Going back now will discard any edits.
              Are you sure you want to leave without saving?
            </Typography>
          </Grid>
        </Grid>
        <Grid width="100%" display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => {
              navigate(-1);
              setOpenBackModal(false);
            }}
          >
            <Typography variant="body3" sx={{ textTransform: "none" }}>
              Don’t save
            </Typography>
          </Button>
          <Button
            // type="submit"
            variant="contained"
            sx={{ width: "48%", height: "48px" }}
            onClick={handleSubmit(onExitAndSave)}
          >
            <Typography
              variant="body3"
              sx={{ textTransform: "none", color: "#fff" }}
            >
              Save and exit
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
