import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import prepPreview from "./assets/prep-preview.png";
import connectingLine from "./assets/connecting-line.png";
import { useFormContext } from "react-hook-form";
import SingleImageUploader from "./components/image-uploader";

export default function PrepSection() {
  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const metaData = getValues("metadata");
  const recipeId = getValues("metadata").recipeId;

  return (
    <Grid container xs={12}>
      <Grid xs={8} container height="50vh">
        <Grid item xs={4.5}>
          <Box
            sx={{
              borderRadius: "8px",
              height: "260px",
              width: "260px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SingleImageUploader
              imageUrl={metaData.prepImage}
              multiple={false}
              storageLocation={`recipes/${recipeId}`}
              name="metadata.prepImage"
              errorMessage={errors?.metadata?.prepImage?.message}
            />
          </Box>
        </Grid>
        <Grid item xs={7.5} mt={6}>
          <img
            src={connectingLine} // Replace with path to your image
            alt="Prep Ingredients"
            style={{ width: "100%" }}
          />
        </Grid>
        <Box mt={4} width="80%">
          <Typography
            variant="h1"
            color="#000"
            sx={{ fontWeight: 700, fontSize: "24px" }}
          >
            Guide Others with a Snapshot!
          </Typography>
          <Typography variant="body3" color="#7C7B79">
            Share a photo featuring all your prep ingredients to provide a
            visual guide and inspire fellow cooks. The right side gives you a
            real-time look at how your uploaded image will appear in the app.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} ml={-4}>
        <img
          src={prepPreview} // Replace with path to your image
          alt="Prep Ingredients"
          style={{ width: "100%", marginTop: "-54px" }}
        />
      </Grid>
    </Grid>
  );
}
