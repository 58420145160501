import React, { useMemo } from "react";
import { useController } from "react-hook-form";
import { Mention, MentionsInput } from "react-mentions";
import mentionsStyle from "./mention-input-styles";
import "./modified.css";

const ModifiedMentionsInput = (props) => {
  const {
    instructionId,
    ingredients = [],
    setCookingAction,
    setSelectedIngredients,
    setMentionTitleArray,
    setDisplayTitle,
    style,
    metaData,
    // recipeInstruction,
    control,
    name,
  } = props;
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  // const actionsForMentions = actions.map((action) => ({
  //   id: action.name,
  //   display: action.name,
  // }));

  const getUsedIngredientsFromText = (text) => {
    if (!text) return [];
    const matches = text.match(/@\[(.*?)\]/g) || [];
    return matches.map((match) => {
      const [name] = match.slice(2, -1).split(",");
      return name.trim();
    });
  };

  const ingredientForMentions = ingredients
    ?.filter((item) => item?.instructionId === "" && item?.name)
    ?.map((ingredient) => ({
      id: [ingredient.name, ingredient?.quantity, ingredient?.units],
      display: ingredient.name,
      quantity: ingredient?.quantity,
      unit: ingredient?.units,
    }));

  const handleMentionsInputChange = (
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    const ingredientNames = ingredients.map((ing) => ing.name);
    // const actionNames = actions.map((act) => act.name);
    // const selectedActions = [];
    const selectionIngredients = [];

    mentions.forEach((mention) => {
      if (ingredientNames.includes(mention.display)) {
        selectionIngredients.push(mention.id);
      }
      // if (actionNames.includes(mention.display)) {
      //   const filterActionId = actions.find(
      //     (item) => item.name === mention.display
      //   );
      //   // selectedActions.push(filterActionId.id);
      //   setCookingAction(filterActionId.id);
      // }
    });

    setMentionTitleArray(selectionIngredients);
    setSelectedIngredients(selectionIngredients, instructionId);
    // console.log(recipeInstruction.action, "action in cooking parameters");
    metaData?.productReleases?.length > 0
      ? newPlainTextValue?.length < 60 && field.onChange(newValue)
      : newPlainTextValue?.length < 160 && field.onChange(newValue);

    setDisplayTitle(newPlainTextValue);

    const currentIngredients = getUsedIngredientsFromText(newValue);
    ingredients.forEach((ing) => {
      if (
        ing.instructionId === instructionId &&
        !currentIngredients.includes(ing.name)
      ) {
        ing.instructionId = ""; // Clear instructionId when ingredient is removed
      }
    });
  };

  return (
    <MentionsInput
      value={field.value}
      onBlur={field.onBlur}
      onChange={handleMentionsInputChange}
      inputRef={field.ref}
      name={field.name}
      style={{ ...style, ...mentionsStyle }}
      placeholder="Use {@} for ingredients"
    >
      <Mention
        trigger="@"
        className="mentions__user"
        data={ingredientForMentions}
        appendSpaceOnAdd
        renderSuggestion={(suggestion, search, highlightedDisplay) => (
          <div style={{ padding: 1 }}>
            {highlightedDisplay}
            <span style={{ marginLeft: "10px" }}>{suggestion.quantity}</span>
            <span style={{ marginLeft: "2px" }}>{suggestion.unit}</span>{" "}
          </div>
        )}
      />
      {/* {!recipeInstruction?.action && (
        <Mention
          trigger="/"
          className="mentions__user"
          data={actionsForMentions}
          appendSpaceOnAdd
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div style={{ padding: 2 }}>
              <span>{highlightedDisplay}</span>
            </div>
          )}
        />
      )} */}
    </MentionsInput>
  );
};
export default ModifiedMentionsInput;
