/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  dietsName,
  nonVegetarian,
  rikuCompatible,
  semiCompatible,
  vegetarian,
} from "../constants";

const useRecipeMetaData = (props) => {
  const { setValue, getValues } = props;

  const formValuesMetaData = getValues("metadata");
  const recipeSections = getValues("recipeSections");
  const [semiSelected, setSemiSelected] = useState(
    formValuesMetaData?.semiSelected || false
  );
  const [rikuSelected, setRikuSelected] = useState(
    formValuesMetaData?.rikuSelected || false
  );

  const [isVerifiedByKlynk, setIsVerifiedKlynk] = useState("no");
  const [difficulty, setDifficulty] = useState(
    formValuesMetaData?.difficultyLevel
  );
  const [servings, setServings] = useState(
    Number(formValuesMetaData?.servings)
  );
  const [totalTime, setTotalTime] = useState(
    formValuesMetaData?.totalTimeInMins
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCourseModelOpen, setIsCourseModelOpen] = useState(false);
  const [isDietSelection, setIsDietSelection] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [productReleases, setProductReleases] = useState([]);

  const handleConfirm = () => {
    setSemiSelected(true);
    setRikuSelected(!rikuSelected);
    setValue("metadata.semiSelected", true);
    setValue("metadata.rikuSelected", !rikuSelected);
    const updatedRecipeSections = recipeSections.map((item) => {
      return {
        ...item,
        sectionType: "manual",
      };
    });
    setValue("recipeSections", updatedRecipeSections);
    setIsModalOpen(false);
  };

  // servings
  const selectServingsHandler = (servingsItem) => {
    setServings(servingsItem);
    setValue("metadata.servings", servingsItem, { shouldDirty: true });
  };

  // difficulty level
  const selectDifficultyHandler = (difficultyItem) => {
    setDifficulty(difficultyItem);
    setValue("metadata.difficultyLevel", difficultyItem, { shouldDirty: true });
  };

  // verified by klynk
  const selectVerifiedKlynkHandler = (verifiedItem) => {
    if (verifiedItem === "yes") {
      setIsVerifiedKlynk(verifiedItem);
      setValue("metadata.isVerifiedByKlynk", true);
    } else {
      setIsVerifiedKlynk(verifiedItem);
      setValue("metadata.isVerifiedByKlynk", false);
    }
  };

  // compatibility handler
  const selectCompatibilityHandler = (index) => {
    if (index === 1) {
      if (rikuSelected) {
        setIsModalOpen(true);
      } else {
        setSemiSelected(true);
        setRikuSelected(!rikuSelected);
        setValue("metadata.semiSelected", true);
        setValue("metadata.rikuSelected", !rikuSelected);
      }
    } else if (index === 0 && !rikuSelected) {
      setSemiSelected(!semiSelected);
      setValue("metadata.semiSelected", !semiSelected);
    }
  };
  const replaceDietsHandler = () => {
    const updatedDietArray = [...formValuesMetaData.diets];
    if (formValuesMetaData.diets.includes(nonVegetarian)) {
      const findNonVegId = updatedDietArray.indexOf(nonVegetarian);
      updatedDietArray[findNonVegId] = vegetarian;
    }
    if (formValuesMetaData.diets.includes(vegetarian)) {
      const findVegId = updatedDietArray.indexOf(vegetarian);
      updatedDietArray[findVegId] = nonVegetarian;
    }
    setIsDietSelection(false);
    setValue(dietsName, updatedDietArray);
  };

  // total time
  const updatingTotalTime = () => {
    if (
      formValuesMetaData?.prepTimeInMins ||
      formValuesMetaData?.cookTimeInMins ||
      formValuesMetaData?.restTimeInMins
    ) {
      const { prepTimeInMins, cookTimeInMins, restTimeInMins } =
        formValuesMetaData;
      let restTime = parseFloat(restTimeInMins) * 60;
      let totalMinutes =
        (parseFloat(prepTimeInMins) || 0) +
        (parseFloat(cookTimeInMins) || 0) +
        (restTime || 0); // Convert restTime from hours to minutes
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;

      let totalTimeStr = "Total time: ";
      if (hours > 0) {
        totalTimeStr += `${hours} hr `;
      }
      if (minutes > 0) {
        totalTimeStr += `${parseFloat(minutes.toFixed(2))} min`;
      }
      setValue(
        "metadata.totalTimeInMins",
        Number(prepTimeInMins) + Number(cookTimeInMins)
      );
      return totalTimeStr;
    }
    return "Total time: 0 min";
  };

  useEffect(() => {
    const checkCompatibility = (releases) => {
      const rikuRegex = /riku/i;
      const semiRegex = /semi/i;

      const hasRiku = releases.some((release) => rikuRegex.test(release));
      const hasSemi = releases.some((release) => semiRegex.test(release));

      return { hasRiku, hasSemi };
    };

    let initialProductReleases = [...formValuesMetaData.productReleases];
    const { hasRiku, hasSemi } = checkCompatibility(initialProductReleases);

    if (hasRiku && !rikuSelected) {
      setSemiSelected(true);
      setRikuSelected(true);
    } else if (hasSemi && !semiSelected) {
      setSemiSelected(true);
      setRikuSelected(false);
    }
  }, [formValuesMetaData.productReleases]);

  useEffect(() => {
    let incomingProductReleases = [...formValuesMetaData.productReleases];

    if (!rikuSelected) {
      incomingProductReleases = incomingProductReleases.filter(
        (item) => !rikuCompatible.includes(item)
      );
    } else {
      [...semiCompatible, ...rikuCompatible].forEach((item) => {
        if (!incomingProductReleases.includes(item)) {
          incomingProductReleases.push(item);
        }
      });
    }

    setValue("metadata.productReleases", incomingProductReleases);
  }, [rikuSelected]);

  useEffect(() => {
    let incomingProductReleases = [...formValuesMetaData.productReleases];

    if (!semiSelected) {
      incomingProductReleases = incomingProductReleases.filter(
        (item) => !semiCompatible.includes(item)
      );
    } else {
      semiCompatible.forEach((item) => {
        if (!incomingProductReleases.includes(item)) {
          incomingProductReleases.push(item);
        }
      });
    }

    setValue("metadata.productReleases", incomingProductReleases);
  }, [semiSelected]);

  useEffect(() => {
    setTotalTime(updatingTotalTime());
  }, [
    formValuesMetaData.restTimeInMins,
    formValuesMetaData.prepTimeInMins,
    formValuesMetaData.cookTimeInMins,
  ]);

  useEffect(() => {
    setServings(Number(formValuesMetaData?.servings));
    setDifficulty(formValuesMetaData?.difficultyLevel);
  }, [formValuesMetaData]);

  return {
    formValuesMetaData,
    isVerifiedByKlynk,
    semiSelected,
    rikuSelected,
    difficulty,
    servings,
    totalTime,
    selectServingsHandler,
    selectDifficultyHandler,
    selectVerifiedKlynkHandler,
    selectCompatibilityHandler,
    updatingTotalTime,
    isModalOpen,
    setIsModalOpen,
    handleConfirm,
    isCourseModelOpen,
    setIsCourseModelOpen,
    isDietSelection,
    setIsDietSelection,
    replaceDietsHandler,
  };
};

export default useRecipeMetaData;
