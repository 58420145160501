import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Skeleton,
  Fade,
  Card,
  CardContent,
} from "@mui/material";
import { useAuth } from "../auth/auth-context";

const LoadingCard = () => (
  <Fade in={true}>
    <Card sx={{ height: "100%", bgcolor: "#f5f5f5", borderRadius: 1 }}>
      <CardContent>
        <Skeleton
          variant="rectangular"
          width="30%"
          height={30}
          sx={{ mb: 1 }}
          animation="wave"
        />
        <Skeleton variant="text" width="60%" animation="wave" height={20} />
      </CardContent>
    </Card>
  </Fade>
);

const DataCard = ({ value, label }) => (
  <Fade in={true}>
    <Box
      sx={{
        height: 100,
        backgroundColor: "#f5f5f5",
        borderRadius: 1,
        padding: 2,
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: 2,
        },
      }}
    >
      <Typography variant="h5" color="primary">
        {value}
      </Typography>
      <Typography variant="body2" color="primary">
        {label}
      </Typography>
    </Box>
  </Fade>
);

export default function Dashboard() {
  const [recipesData, setRecipesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user, api } = useAuth();

  const fetchRecipesCount = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/dashboard");
      setRecipesData(response.data.data);
    } catch (error) {
      console.error("Error fetching recipes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecipesCount();
  }, [user]);

  const placeholderCards = Array.from({ length: 15 }, (_, index) => index);

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Dashboard</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2} sx={{ height: "88vh", overflow: "auto" }}>
        {isLoading
          ? placeholderCards.map((index) => (
              <Grid item xs={12} sm={6} md={4} key={`loading-${index}`}>
                <LoadingCard />
              </Grid>
            ))
          : Object.entries(recipesData).map(([key, value], index) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <DataCard value={value} label={key} />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
}
