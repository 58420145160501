import React from "react";

import allergiesModel from "./allergies-model";
import EntityTable from "../entities/entity-table";

export default function AllergiesTable(props) {
  return (
    <EntityTable
      entityName="allergies"
      entityModel={allergiesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
