import React from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
// import ComingSoon from "../assets/coming-soon.png";
import ComingSoon from "../assets/coming-soon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  // height: 265,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 15px",
};

export default function PreviewModal(props) {
  const { openPreviewModal, setOpenPreviewModal, setCurrentFormView } = props;

  return (
    <Modal
      open={openPreviewModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          xs={12}
          container
          flexDirection="column"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item>
            <img src={ComingSoon} alt="" style={{ width: 40, height: 40 }} />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              Interactive Preview Coming Soon
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="body4" sx={{ lineHeight: "22px" }}>
              We are brewing up the Interactive Preview feature, allowing you to
              dive into and interact with your content exactly as it appears on
              the app. Keep an eye out for the grand unveiling!
            </Typography>
          </Grid>
        </Grid>
        <Grid width="100%" display="flex" mt={2}>
          <Button
            variant="contained"
            sx={{
              height: "48px",
              width: "100%",
              background: "linear-gradient(90deg, #EF4823 0%, #F47422 100%)",
            }}
            onClick={() => {
              setOpenPreviewModal(false);
              setCurrentFormView("INSTRUCTIONS");
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textTransform: "none",
                color: "#fff",
              }}
            >
              Close
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
