import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import ModifiedTextField from "./modified-field/modified-text-field";
import { useFormContext } from "react-hook-form";

export default function ServeSection() {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();
  const metaData = getValues("metadata");

  return (
    <Grid container spacing={3} xs={12}>
      <Grid mt={4} item xs={12}>
        <Typography ml={1} variant="h5" sx={{ fontWeight: 700 }}>
          Serve Instructions
          <span style={{ color: "#EF4823" }}>*</span>
        </Typography>
        <ModifiedTextField
          placeholder="Your recipe is a masterpiece! Help cooks bring it to life with your serving suggestions. Share plating tips, recommend accompaniments, complementary dips, and guide them to ensure that every serving does justice to your recipe."
          fullWidth
          style={{ background: "#fff", padding: "5px" }}
          control={control}
          name="metadata.recipeServeDescription"
          multiline={true}
          inputProps={{ maxLength: 250 }}
          rows={4}
          variant="outlined"
          error={errors?.metadata?.recipeServeDescription}
        />
        <Box display="flex" justifyContent="space-between">
          {errors?.metadata?.recipeServeDescription && (
            <Typography variant="caption2" color="#EF4823">
              {errors?.metadata?.recipeServeDescription?.message}
            </Typography>
          )}
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <Typography variant="caption1" sx={{ textAlign: "end", mt: 1 }}>{`${
              metaData?.recipeServeDescription?.length || 0
            } / 250`}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
