/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { string, bool } from "yup";
import { nameValidation } from "../units/units-model";

const styles = {
  listLayoutComponent: {
    maxWidth: "100%",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};

const formatDate = (dateValue) => {
  if (!dateValue) return "";
  
  const date = normalizeDate(dateValue);
  if (!date) {
    console.log("Invalid date:", dateValue);
    return "";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const normalizeDate = (timestamp) => {
  if (!timestamp) return null;
  if (timestamp.seconds) return new Date(timestamp.seconds * 1000);
  if (timestamp instanceof Date) return timestamp;
  return new Date(timestamp);
};

const CookingActions = {
  name: "Actions",
  model: "actions",
  collectionName: "actions",
  defaultObject: {
    id: "",
    name: null,
    description: "",
    type: "",
    showInRecipeBuilder: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  fields: [
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const { name } = props.row || {};
        return (
          <div style={styles.listLayoutComponent}>
            <span style={styles.capitalize}>{name}</span>
          </div>
        );
      },
    },
    {
      name: "type",
      dataType: "String",
      inputFieldType: "select",
      options: ["Heat", "Non-heat"],
      label: "Type",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 125,
      required: true,
      validation: string().required("Type is required"),
      placeholder: "Select the type",
    },
    {
      name: "showInRecipeBuilder",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Show in Recipe Builder",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("Required"),
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const { description } = props.row || {};
        return (
          <div style={styles.listLayoutComponent}>
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Created At",
      showInListLayout: true,
      listLayoutWidth: 125,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutComponent}>
          <span>{formatDate(props.row?.createdAt || "")}</span>
        </div>
      ),
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Last Updated",
      showInListLayout: true,
      listLayoutWidth: 125,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutComponent}>
          <span>{formatDate(props.row?.updatedAt || "")}</span>
        </div>
      ),
    },
  ],
  layout: [
    [{ name: "name", width: 6 }],
    [{ name: "showInRecipeBuilder", width: 6 }],
    [{ name: "type", width: 3 }],
    [{ name: "description", width: 12 }],
  ],
};

export default CookingActions;
