import React, { createContext, useEffect, useMemo, useState } from "react";
import useEntity from "./use-entity";

export const RecipeEntityContext = createContext();

const RecipeEntityProvider = ({ children }) => {
  const { getEntities, getEntitiesByKey } = useEntity();
  const [tools, setTools] = useState([]);
  const [tags, setTags] = useState([]);
  const [courses, setCourses] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [diets, setDiets] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [units, setUnits] = useState([]);
  const [prepStyles, setPrepStyles] = useState([]);
  const [modeSets, setModeSets] = useState([]);
  const [cookingPresets, setCookingPresets] = useState([]);
  const [cookingActions, setCookingActions] = useState([]);
  const [recipeFilters, setRecipeFilters] = useState([]);

  useEffect(() => {
    // Fetching entities and setting them in state
    getEntities("tools", setTools);
    getEntities("courses", setCourses);
    getEntities("cuisines", setCuisines);
    getEntities("allergies", setAllergies);
    getEntities("diets", setDiets);
    getEntities("tags", setTags);
    getEntities("ingredients", setIngredients);
    getEntities("units", setUnits);
    getEntities("prepStyles", setPrepStyles);
    getEntities("recipeFilters", setRecipeFilters);
    // entityName, key, keyValue, localSetter;
    getEntitiesByKey(
      "cookingPresets",
      "isKlynkPreset",
      false,
      setCookingPresets
    );
    getEntitiesByKey("actions", "showInRecipeBuilder", true, setCookingActions);
    getEntities("controlModes", setModeSets);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      tools,
      tags,
      courses,
      cuisines,
      allergies,
      diets,
      ingredients,
      units,
      prepStyles,
      modeSets,
      cookingPresets,
      cookingActions,
      recipeFilters,
    }),
    [
      allergies,
      courses,
      cuisines,
      diets,
      ingredients,
      prepStyles,
      tags,
      tools,
      units,
      modeSets,
      cookingPresets,
      cookingActions,
      recipeFilters,
    ]
  );
  return (
    <RecipeEntityContext.Provider value={value}>
      {children}
    </RecipeEntityContext.Provider>
  );
};

export default RecipeEntityProvider;
