import React from "react";

import prepStylesModel from "./prep-styles-model";
import EntityTable from "../entities/entity-table";

export default function PrepStylesTable(props) {
  return (
    <EntityTable
      entityName="prepStyles"
      entityModel={prepStylesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
