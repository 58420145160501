/* eslint-disable no-unused-vars */
import React from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import PreviewModal from "./components/preview-popup";

function Preview(props) {
  const { openPreviewModal, setOpenPreviewModal, setCurrentFormView } = props;

  return (
    <Grid container>
      <PreviewModal
        openPreviewModal={openPreviewModal}
        setOpenPreviewModal={setOpenPreviewModal}
        setCurrentFormView={setCurrentFormView}
      />
    </Grid>
  );
}
export default Preview;
