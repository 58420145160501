import React from "react";

import coursesModel from "./courses-model";
import EntityTable from "../entities/entity-table";

export default function CoursesTable(props) {
  return (
    <EntityTable
      entityName="courses"
      entityModel={coursesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
