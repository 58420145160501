import { useState, useEffect } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      return value ? JSON.parse(value) : defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(storedValue));
    } catch (err) {
      console.error("Failed to save to localStorage", err);
    }
  }, [keyName, storedValue]);

  return [storedValue, setStoredValue];
};
