import React, { useState } from "react";
import { useController } from "react-hook-form";
import { FormControl, FormLabel, MenuItem, Select, FormHelperText } from "@mui/material";

const Customlayoutselect = ({
  control,
  name,
  value,
  label,
  options = [],
  error,
  helperText,
  size,
  fullWidth,
  multiple,
}) => {
  const { field } = useController({ name, control });
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(option => option.value === selectedValue);
    setSelectedOption(selectedOption);
    field.onChange(selectedValue);
  };

  return (
    <FormControl size={size} fullWidth={fullWidth} error={error}>
      <FormLabel>{label}</FormLabel>
      <Select
        size={size ? size : 'small'}
        label={label}
        id={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        name={field.name}
        inputRef={field.ref}
        value={field.value || ''}
        fullWidth={fullWidth}
        multiple={multiple}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 250,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {selectedOption && (
        <img
          src={selectedOption.imageUrl}
          alt={selectedOption.label}
          style={{ width: "100%", maxWidth: "200px", marginTop: "10px" }}
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Customlayoutselect;
