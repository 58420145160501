import React from "react";

import toolsModel from "./tools-model";
import EntityTable from "../entities/entity-table";

export default function ToolsTable(props) {
  return (
    <EntityTable
      entityName="tools"
      entityModel={toolsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
