import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useController } from "react-hook-form";

const ControlledCheckbox = (props) => {
  const { control, name, label } = props;
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          inputRef={field.ref}
          checked={field.value}
        />
      }
      label={label}
    />
  );
};
export default ControlledCheckbox;
