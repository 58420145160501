import React, { useState, useRef, useEffect } from "react";
import { EmptyImageBox } from "../../rct-isolated-section/components/styles";
import fileUpload from "../../rct-isolated-section/assets/file-upload.png";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { useController } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { storage, firestore } from "../../../config/firebase-config";

const ControlledMediaSelect = (props) => {
  const {
    control,
    name,
    options = [],
    label,
    handleMediaUpload,
    storageLocation,
    error,
  } = props;

  const { field } = useController({ name, control });
  const [mediaFiles, setMediaFiles] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Fetch existing media files from Firestore
    const fetchMediaFiles = async () => {
      const mediaFilesSnapshot = await firestore.collection("mediaFiles").get();
      const mediaFilesData = {};
      mediaFilesSnapshot.forEach((doc) => {
        mediaFilesData[doc.id] = doc.data();
      });
      setMediaFiles(mediaFilesData);

      // Set initial selectedOption if it exists in the form control
      if (field.value) {
        setSelectedOption(field.value);
      }
    };
    fetchMediaFiles();
  }, [field.value]);

  const handleFileChange = async (e, option) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const uuid = uuidv4();
        const fileName = `${storageLocation}--${uuid}--${file.name}`;
        const storageRef = storage
          .ref()
          .child(`${storageLocation}/original/${fileName}`);

        const snapshot = await storageRef.put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();

        const fileType = file.type.startsWith("image/") ? "image" : "video";

        const docRef = firestore.collection("mediaFiles").doc(option);
        await docRef.set({
          url: downloadURL,
          mediaUrl: downloadURL,
          option: option,
          fileType: fileType,
        });

        setMediaFiles((prev) => ({
          ...prev,
          [option]: {
            url: downloadURL,
            fileType: fileType,
          },
        }));

        handleMediaUpload && handleMediaUpload({ [option]: downloadURL });

        const newSelectedOption = {
          option,
          mediaUrl: downloadURL,
          fileType: fileType,
        };
        setSelectedOption(newSelectedOption);
        // Update the form field value
        field.onChange({
          target: {
            name: field.name,
            value: newSelectedOption,
          },
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const existingMedia = mediaFiles[value];

    const newSelectedOption = {
      option: value,
      mediaUrl: existingMedia?.url || null,
      fileType: existingMedia?.fileType || null,
    };
    setSelectedOption(newSelectedOption);

    field.onChange({
      target: {
        name: field.name,
        value: newSelectedOption,
      },
    });
  };

  const handleDelete = async () => {
    if (selectedOption) {
      try {
        // Remove from Firestore
        const docRef = firestore
          .collection("mediaFiles")
          .doc(selectedOption.option);
        await docRef.delete();
        // Update state
        setMediaFiles((prev) => {
          const newMediaFiles = { ...prev };
          delete newMediaFiles[selectedOption.option];
          return newMediaFiles;
        });

        setSelectedOption((prev) => ({
          ...prev,
          mediaUrl: null,
          fileType: null,
        }));

        field.onChange({
          target: {
            name: field.name,
            value: {
              ...selectedOption,
              mediaUrl: null,
              fileType: null,
            },
          },
        });
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Select
        size="small"
        label={label}
        id={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        name={field.name}
        inputRef={field.ref}
        value={selectedOption?.option || ""}
        fullWidth
        renderValue={(selected) => (
          <span style={{ textTransform: "capitalize" }}>{selected}</span>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={{ textTransform: "capitalize" }}
          >
            {" "}
            {option}
          </MenuItem>
        ))}
      </Select>

      {selectedOption && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            mt: 2,
          }}
        >
          {(selectedOption.mediaUrl || selectedOption.option) && (
            <EmptyImageBox
              sx={{
                border: "2px dotted #A7A7A7",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              {selectedOption.mediaUrl ? (
                selectedOption.fileType === "image" ? (
                  <img
                    src={selectedOption.mediaUrl}
                    alt="Selected Media"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#000",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <video
                      src={selectedOption.mediaUrl}
                      controls
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                )
              ) : (
                <>
                  <img src={fileUpload} height="16px" width="16px" alt="" />
                  <Typography my={1} variant="subtext2" textAlign="center">
                    Choose Image or Video to Upload
                  </Typography>
                  <Box textAlign="center">
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "14px", color: "#7C7B79" }}
                    >
                      Supports JPG, JPEG, PNG, MP4<span> (optional)</span>
                    </Typography>
                    {error && (
                      <Typography
                        variant="body2"
                        sx={{ color: "#D32F2F", fontSize: "12px" }}
                      >
                        {error.message || "Media is required"}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
              <input
                type="file"
                accept="image/*, video/*"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, selectedOption.option)}
                style={{ display: "none" }}
              />
            </EmptyImageBox>
          )}

          {selectedOption.mediaUrl && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
              }}
            >
              <IconButton onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDelete}
                disabled={!selectedOption.mediaUrl}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </FormControl>
  );
};

ControlledMediaSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  handleMediaUpload: PropTypes.func,
  storageLocation: PropTypes.string.isRequired,
};

ControlledMediaSelect.defaultProps = {
  label: "",
  handleMediaUpload: () => {},
};

export default ControlledMediaSelect;
