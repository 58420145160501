import React from "react";
import unitsModel from "./units-model";
import EntityTable from "../entities/entity-table";

export default function UnitsTable(props) {
  return (
    <EntityTable
      entityName="units"
      entityModel={unitsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
