/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { nameValidation } from "../units/units-model";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";

const styles = {
  listLayoutComponent: {
    maxWidth: "100%",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  thumbnailImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};

const formatDate = (dateValue) => {
  if (!dateValue) return "";
  const date = normalizeDate(dateValue);
  if (!date) return "";

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const normalizeDate = (timestamp) => {
  if (!timestamp) return null;
  if (timestamp.seconds) return new Date(timestamp.seconds * 1000);
  if (typeof timestamp === "string" || typeof timestamp === "number") {
    return new Date(timestamp);
  }
  if (timestamp instanceof Date) return timestamp;
  return null;
};

const aislesModel = {
  name: "Aisles",
  model: "aisles",
  route: "aisles",
  collectionName: "aisles",
  description: "Aisles are the main sections of the store.",
  defaultObject: {
    id: null,
    name: null,
    description: null,
    thumbnailUrl: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 75,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={styles.thumbnailImage}
          />
        );
      },
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div style={styles.listLayoutComponent}>
            <span style={styles.capitalize}>{name}</span>
          </div>
        );
      },
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div style={styles.listLayoutComponent}>
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Last Updated",
      showInListLayout: true,
      listLayoutWidth: 125,
      valueGetter: (params) => normalizeDate(params.row.updatedAt),
      renderCell: (props) => (
        <span>{props.row.updatedAt ? formatDate(props.row.updatedAt) : ""}</span>
      ),
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutComponent}>
          <span>{formatDate(props.row?.updatedAt)}</span>
        </div>
      ),
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Created At",
      showInListLayout: true,
      listLayoutWidth: 125,
      valueGetter: (params) => {
        const date = normalizeDate(params.row.createdAt);
        return date ? formatDate(date) : "";
      },
      renderCell: (props) => {
        const date = normalizeDate(props.row.createdAt);
        return date ? <span>{formatDate(date)}</span> : "";
      },
      listLayoutComponent: (props) => {
        const date = normalizeDate(props.row?.createdAt);
        return (
          <div style={styles.listLayoutComponent}>
            <span>{date ? formatDate(date) : ""}</span>
          </div>
        );
      },
    },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 12 }],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
    {
      name: "edit",
      label: "Edit",
      type: "edit",
      icon: "edit",
      color: "primary",
    },
    {
      name: "delete",
      label: "Delete",
      type: "delete",
      icon: "delete",
      color: "primary",
    },
  ],
};

export default aislesModel;
