/* eslint-disable valid-typeof */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography, Box, Breadcrumbs, Snackbar } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import useEntity from "../entities/use-entity";
import recipeModel from "./recipe-model";
import { ViewInArTwoTone } from "@mui/icons-material";

function maxDepth(obj) {
  if (typeof obj !== "object" || obj === null) {
    return 0;
  }

  let max = 0;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let depth = maxDepth(obj[key]);
      if (depth > max) {
        max = depth;
      }
    }
  }
  return max + 1;
}

const RecipeInstructionsTable = (props) => {
  const navigate = useNavigate();
  const { loading, loadingMessage, getEntities, deleteEntities, deleteEntity } =
    useEntity();
  const [recipes, setRecipes] = useState([]);
  const [rowData, setRowData] = useState([]);
  const entityName = "testRecipes";

  const entityDepth = maxDepth(recipeModel);
  const [selectedFeatureAtDepth, setSelectedFeatureAtDepth] = useState(
    new Array(entityDepth).fill(null)
  );

  const [level1, setLevel1] = useState([]);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState([]);
  const [level4, setLevel4] = useState(null);
  const [level5, setLevel5] = useState([]);

  const handleFeatureSelection = (feature, depth) => {
    let newSelectedFeatureAtDepth = [...selectedFeatureAtDepth];
    newSelectedFeatureAtDepth[depth] = feature;
    setSelectedFeatureAtDepth(newSelectedFeatureAtDepth);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshData = async () => {
    await getEntities(entityName, setRecipes);
  };

  useEffect(() => {
    refreshData();
    return () => {
      setRecipes([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(recipes.filter((r) => r.title == "Gongura Chicken"));
    if (recipes.length > 0) {
      let data = [];
      recipes.forEach((recipe) => {
        recipe.recipeInstructions.forEach((section, sectionIndex) => {
          section.itemListElement.forEach((instruction, instructionIndex) => {
            let newEntry = {};
            newEntry = {
              id: recipe.id + sectionIndex + instructionIndex,
              rid: recipe.id,
              title: recipe.title,
              author: recipe.author,
              sectionIndex: sectionIndex,
              sectionName: section.title,
              instructionIndex: instructionIndex,
              parsedInstruction: instruction.mentionsTitle,
              plainInstruction: instruction.title,
              action: instruction.cookingParameters.action,
              selectedActions: instruction.selectedActions,
              selectedIngredients: instruction.selectedIngredients,
              selectedTools: instruction.selectedTools,
              productReleases: recipe.productReleases,
            };
            data.push(newEntry);
          });
        });
      });
      setRowData(data);
    }
  }, [recipes]);

  // Handle row selection
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = (newSelection) => {
    console.log(newSelection);
    if (newSelection.length > 0) {
      setSelectedRows(newSelection);
    }
  };

  // Handle Edit and Delete
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(true);

  const deleteRecipes = async () => {
    alert("Are you sure you want to delete these recipes?");
    if (selectedRows.length > 0) {
      await deleteEntities(entityName, selectedRows);
    }
    setSelectedRows([]);
    await refreshData();
  };

  useEffect(() => {
    if (selectedRows.length == 0) {
      setCanDelete(false);
      setCanEdit(false);
    } else if (selectedRows.length == 1) {
      setCanEdit(true);
      setCanDelete(true);
    } else {
      setCanEdit(false);
      setCanDelete(true);
    }
    console.log(selectedRows.length);
    console.log(canEdit);
  }, [canEdit, selectedRows]);

  // Handle Search
  const [searchByColumn, setSearchByColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Handle Filter
  const [filterValue, setFilterValue] = useState("");
  const column = [
    { field: "rid", headerName: "RID", width: 100 },
    { field: "title", headerName: "Title", width: 150 },
    {
      field: "linkTo",
      headerName: "View",
      width: 50,
      renderCell: (props) => (
        <Link to={`/recipes/${props.row.rid}`} state={props.row}>
          <ViewInArTwoTone />{" "}
        </Link>
      ),
    },
    {
      field: "author",
      headerName: "Author",
      width: 100,
      renderCell: (props) => {
        const recipe = props.row;
        if (recipe.author && typeof recipe.author === "array") {
          return recipe.author[0].name;
        } else if (recipe.author && typeof recipe.author === "object") {
          return recipe.author.name;
        } else {
          return "";
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "LastUpdated",
      width: 200,
      valueGetter: (params) => {
        return params.row.updatedAt ? params.row.updatedAt.toString() : "";
      },
    },
    // { field: "recipeYield", headerName: "Yield", width: 50 },
    // { field: "klynkAppRecipe", headerName: "OnKlynkApp?", width: 100 },
    // { field: "recipeIngredients", headerName: "#Ingredients", width: 100 , renderCell: (props) => {
    //         return(
    //             <Tooltip title={props.row.recipeIngredients?.map(ingredient => ingredient.name).join(",")} followCursor>
    //                 <Box>
    //                     {props.row.recipeIngredients ? props.row.recipeIngredients.length: 0}
    //                 </Box>
    //             </Tooltip>
    //         )
    //     },
    // },
    { field: "sectionIndex", headerName: "#Sec", width: 50 },
    { field: "sectionName", headerName: "Section", width: 100 },
    { field: "instructionIndex", headerName: "#Inst", width: 50 },
    { field: "plainInstruction", headerName: "Plain Instruction", width: 400 },
    {
      field: "parsedInstruction",
      headerName: "Parsed Instruction",
      width: 400,
    },
    { field: "action", headerName: "Action", width: 150 },
    { field: "selectedActions", headerName: "Selected Actions", width: 200 },
    {
      field: "selectedIngredients",
      headerName: "Selected Ingredients",
      width: 200,
    },
    { field: "selectedTools", headerName: "Selected Tools", width: 200 },
    {
      field: "productReleases",
      headerName: "Compatibility",
      width: 200,
      renderCell: (props) =>
        props.row.productReleases
          ? props.row.productReleases.map((val) => val).join(" , ")
          : "",
    },
    // { field: "recipeInstructions", headerName: "#Instructions", width:100 , renderCell: (props) => props.row.recipeInstructions?.length},
    // { field: "prepTime", headerName: "PrepTime", width: 100 ,
    //     renderCell: (props) => {
    //         return props.row.prepTime?.value ? props.row.prepTime.value + " " + props.row.prepTime.units : ""
    //      },
    // },
    // { field: "cookTime", headerName: "CookTime", width: 100,
    //   renderCell: (props) => {
    //     return props.row.cookTime?.value ? props.row.cookTime.value + " " + props.row.cookTime.units : ""
    //  },},
    // { field: "sourceLink", headerName: "Source Link", width: 250 },
    // { field: "recipeCategory", headerName: "Category", width: 250 },
    // { field: "tags", headerName: "Tags", width: 250 },
    // { field: "recipeDescription", headerName: "Description", width: 250 },
    // {
    //     field: "createdAt", headerName: "CreatedAt", width: 200, valueGetter: (params) => {
    //         return params.row.createdAt ? params.row.createdAt.toString() : ""
    //     }
    // },
  ];

  // Handle Modal
  const [formMode, setFormMode] = useState("createMode");
  const [formData, setFormData] = useState({});
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const toggleCreateEditModal = (mode) => {
    if (mode == "createMode") {
      setFormMode("create");
    } else {
      setFormMode("edit");
      setFormData(rowData.filter((row) => row.id == selectedRows[0])[0]);
      navigate(`/create-recipe-pro/${selectedRows[0]}`);
    }
    setIsCreateEditModalOpen(!isCreateEditModalOpen);
  };

  return (
    <Box padding={2} style={{ height: "100%" }}>
      {/* <Modal open={isCreateEditModalOpen} onClose={toggleCreateEditModal} mode={formMode} data={formData}>
                <Box style={style}>
                    <Crea  />
                </Box>
            </Modal> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/entities">
              Enitities
            </Link>
            <Typography color="text.primary">Recipes</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">Recipe Model Query</Typography>
            </Grid>
            {/* <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end'}}>                           
                            <IconButton aria-label="" onClick={() => navigate('/create-recipe-pro')}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                            <IconButton aria-label="" disabled={!canEdit} onClick={() => toggleCreateEditModal()}>
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton aria-label="" disabled={!canDelete} onClick={() => deleteRecipes()}>
                              <DeleteIcon />
                            </IconButton>
                        </Grid> */}
          </Grid>
          {/* <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    id="combo-box-demo"
                                    options={Object.keys(recipeModel['defaultObject']).filter(key => typeof(recipeModel[key]) != 'object')}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label={""}  variant="outlined" />} 
                                    onChange={(event, newValue) => {
                                        setLevel1(newValue)
                                        setLevel2("")
                                        setLevel3([])
                                        setLevel4("")
                                        setLevel5([])
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    id="combo-box-demo"
                                    options={Object.keys(recipeModel['defaultObject']).filter(key => typeof(recipeModel[key]) == 'object')}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label={""}  variant="outlined" />} 
                                    onChange={(event, newValue) => {
                                        setLevel2(newValue)
                                        setLevel3([])
                                        setLevel4("")
                                        setLevel5([])
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {
                            level2 &&
                        (<>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    id="combo-box-demo"
                                    options={Object.keys(recipeModel['defaultObject'][level2]).filter(key => typeof(recipeModel[key]) == 'object')}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label={""}  variant="outlined" />} 
                                    onChange={(event, newValue) => {                                       
                                        setLevel3(newValue)
                                        setLevel4("")
                                        setLevel5([])
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    id="combo-box-demo"
                                    options={Object.keys(recipeModel['defaultObject']).filter(key => typeof(recipeModel[key]) == 'object')}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label={""}  variant="outlined" />} 
                                    onChange={(event, newValue) => {
                                        setLevel4(newValue)
                                        setLevel5([])
                                    }}
                                />
                            </FormControl>
                        </Grid> 
                        </>)
                        }
                        {
                            level4 &&
                        (<>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    multiple={true}
                                    id="combo-box-demo"
                                    options={Object.keys(recipeModel['defaultObject'][level2]).filter(key => typeof(recipeModel[key]) == 'object')}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => <TextField {...params} label={""}  variant="outlined" />} 
                                    onChange={(event, newValue) => {                                       
                                        setLevel3(newValue)
                                        setLevel4("")
                                        setLevel5([])
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        </>)
                        }
                    </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "85vh", width: "100%" }}>
            <DataGrid
              components={{ Toolbar: GridToolbar }}
              rows={rowData}
              columns={column}
              pageSize={100}
              rowsPerPageOptions={[20]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(rows) => setSelectedRows(rows)}
              // experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecipeInstructionsTable;

const style = {
  position: "absolute",
  top: 0,
  right: 0,
  width: "50%",
  // transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
