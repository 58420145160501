import { AppBar, Button, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const AppBarContainer = styled(AppBar)({
  backgroundColor: "#FFF",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
  height: "60px",
});
export const ArrowBack = styled(ArrowBackIcon)({
  marginRight: "5px",
  color: "#393939",
  cursor: "pointer",
  width: 30,
  height: 24,
});
export const SaveButton = styled(Button)({
  borderRadius: "5px",
  height: 36,
  padding: "10px 20px",
  background: "linear-gradient(90deg, #EF4823 0%, #F47422 100%)",
});
