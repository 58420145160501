/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";

import recipeFiltersModel from "./recipe-filters-model";
import EntityTable from "../entities/entity-table";

const RecipeFiltersTable = (props) => {
  return (
    <EntityTable
      entityName="recipeFilters"
      entityModel={recipeFiltersModel}
      canEdit={true}
      canDelete={true}
    />
  );
};

export default RecipeFiltersTable;
