const recipeModel = {
  name: "recipes",
  model: "recipes",
  collectionName: "recipes",
  defaultObject: {
    title: "",
    author: {
      name: "",
      id: "",
      photoURL: "",
      email: "",
    },
    recipeImages: [],
    recipeDescription: "",
    difficultyLevel: "",
    prepTime: 0,
    cookTime: 0,
    servings: 0,
    recipeIngredients: [
      {
        name: "",
        ingredient: "",
        units: "",
        prepStyle: "",
        quantity: 0,
        isOutput: false,
      },
    ],
    recipeSections: [
      {
        sectionName: "",
        title: "",
        instructions: [
          {
            title: "",
            stepImageUrl: "",
            tip: "",
            ingredients: [
              {
                isRecipeGenerated: false,
                isUserGenerated: false,
                isOutput: false,
                name: "",
                id: "",
                quantity: 0,
                units: "",
                prepStyle: "",
              },
            ],
            selectedActions: [],
            selectedIngredients: [],
            cookingParameters: {
              stirring: false,
              isLidOpen: false,
              action: "",
              power: 0,
              temperature: 0,
              weight: 0,
              duration: 0,
            },
            // semiSteps: [
            //     {
            //         "tip": "",
            //         "title": "",
            //     }
            // ],
            tools: [],
          },
        ],
      },
    ],
    notes: "",
    tags: [],
    profileUrl: "",
    recipeUrl: "",
    imageUrl: "",
  },
  fields: [
    {
      name: "name",
      helperText: "Name of the recipe",
      fieldName: "name",
      dataType: "String",
      inputFieldType: "text",
      label: "Name",
    },
    {
      name: "author",
      helperText: "Author of the recipe",
      fieldName: "author",
      dataType: "User",
      inputFieldType: "Autocomplete",
      label: "Author",
    },

    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
    },
    {
      name: "profileUrl",
      dataType: "String",
      inputFieldType: "ImageUploader",
      label: "Profile Image",
      required: false,
    },
    {
      name: "recipeImages",
      dataType: "Array",
      inputFieldType: "ImageUploader",
      label: "Images",
      required: false,
    },
    {
      name: "recipeIngredients",
      dataType: "Array",
      inputFieldType: "RecipeIngredients",
      label: "Ingredients",
      required: false,
    },
    {
      name: "recipeInstructions",
      dataType: "Array",
      inputFieldType: "RecipeInstructions",
      label: "Instructions",
      required: false,
    },
    {
      name: "servings",
      dataType: "Number",
      inputFieldType: "number",
      label: "Servings",
      required: false,
    },
    {
      name: "prepTime",
      dataType: "Number",
      inputFieldType: "number",
      label: "Prep Time",
      required: false,
    },
    {
      name: "cookTime",
      dataType: "Number",
      inputFieldType: "number",
      label: "Cook Time",
      required: false,
    },
    {
      name: "difficultyLevel",
      dataType: "String",
      inputFieldType: "select",
      options: ["Easy", "Medium", "Hard"],
      label: "Difficulty Level",
      required: false,
    },
    {
      name: "notes",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Notes",
      required: false,
    },
    {
      name: "tags",
      dataType: "Array",
      inputFieldType: "Autocomplete",
      label: "Tags",
      required: false,
    },
    {
      name: "diets",
      dataType: "Array",
      inputFieldType: "Autocomplete",
      label: "Diets",
      required: false,
    },
    {
      name: "cuisines",
      dataType: "Array",
      inputFieldType: "Autocomplete",
      label: "Cuisines",
      required: false,
    },
    {
      name: "courses",
      dataType: "Array",
      inputFieldType: "Autocomplete",
      label: "Courses",
      required: false,
    },
  ],
  layout: [
    [{ name: "thumbnails", width: 6 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default recipeModel;
