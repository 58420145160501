/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const creatorLeads = {
  name: "Creator Leads",
  model: "creatorLeads",
  collectionName: "creatorLeads",
  defaultObject: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    websiteUrl: "",
    instagramUrl: "",
    youtubeUrl: "",
    facebookUrl: "",
    twitterUrl: "",
    tiktokUrl: "",
    pinterestUrl: "",
    snapchatUrl: "",
  },
  fields: [
    {
      name: "firstName",
      dataType: "String",
      inputFieldType: "text",
      label: "First Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Name is required"),
    },
    {
      name: "lastName",
      dataType: "String",
      inputFieldType: "text",
      label: "Last Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Name is required"),
      listLayoutComponent: undefined,
    },
    {
      name: "email",
      dataType: "String",
      inputFieldType: "text",
      label: "Email",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Name is required"),
    },
    {
      name: "phone",
      dataType: "String",
      inputFieldType: "text",
      label: "Phone",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
    {
      name: "websiteUrl",
      dataType: "String",
      inputFieldType: "text",
      label: "Website Url",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Name is required"),
    },
  ],
  layout: [
    [
      { name: "firstName", width: 6 },
      { name: "lastName", width: 6 },
    ],
    [
      { name: "email", width: 6 },
      { name: "phone", width: 6 },
    ],

    [{ name: "websiteUrl", width: 12 }],
    [
      { name: "youtubeUrl", width: 6 },
      { name: "facebookUrl", width: 6 },
    ],
    [
      { name: "instagramUrl", width: 6 },
      { name: "tiktokUrl", width: 6 },
    ],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default creatorLeads;
