import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  // breakpoints: {
  //   keys: ["xs", "sm", "md", "lg", "xl"],
  //   values: {},
  // },
  palette: {
    mode: "light",
    primary: {
      main: "#EF4329",
      light: "#f26853",
      dark: "#a72e1c",
    },
    secondary: {
      main: "#292929",
      light: "#535353",
      dark: "#1c1c1c",
    },
    info: {
      main: "#ffffff",
    },
    success: {
      main: "#659F34",
      light: "#83b25c",
      dark: "#466f24",
    },
    grey: {
      main: "#8F8F8F",
      light: "#a5a5a5",
      dark: "#646464",
    },
    warning: {
      main: "#E89601",
      light: "#ecab33",
      dark: "#a26900",
    },
  },
  typography: {
    fontFamily: { main: "Roboto Slab", secondary: "Mukta Mahee" },
    useNextVariants: true,
    // using typography fonts when design team gives.
    h1: {
      fontFamily: "Roboto Slab",
      fontSize: "h4.fontSize",
      fontWeight: 700,
      color: "#EF4329",
    },
    h2: {
      fontFamily: "Roboto Slab",
      fontSize: "18px",
      fontWeight: 700,
      color: "#292929",
    },
    h3: {
      fontFamily: "Roboto Slab",
      fontSize: "16px",
      fontWeight: 700,
      color: "#292929",
    },
    h4: {
      fontFamily: "Roboto Slab",
      // fontSize: "0.875rem",
      fontWeight: 700,
      color: "#292929",
    },
    h5: {
      fontFamily: "Roboto Slab",
      // fontSize: "0.75rem",
      fontWeight: 700,
      color: "#292929",
    },
    h6: {
      fontFamily: "Roboto Slab",
      // fontSize: "0.625rem",
      fontWeight: 700,
      color: "#292929",
    },
    subtitle1: {
      fontFamily: "Mukta Mahee",
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#292929",
    },
    subtitle2: {
      fontFamily: "Mukta Mahee",
      fontSize: "0.75rem",
      fontWeight: 400,
      color: "#292929",
    },
    body1: {
      fontFamily: "Mukta Mahee",
      fontSize: "1rem",
      fontWeight: 400,
      color: "#292929",
    },
    body2: {
      fontFamily: "Mukta Mahee",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#292929 #ef4329",
    },
    button1: {
      fontFamily: "Roboto Slab",
      // fontSize: "1.125rem",
      fontWeight: 700,
      color: "#292929",
    },
    button2: {
      fontFamily: "Mukta Mahee",
      // fontSize: "0.875rem",
      fontWeight: 700,
      color: "#292929",
    },
    subHeading1: {
      fontFamily: "Mukta Mahee",
      fontSize: "1.125rem",
      fontWeight: 700,
      color: "#292929",
    },
    subHeading2: {
      fontFamily: "Mukta Mahee",
      fontSize: "1rem",
      fontWeight: 700,
      color: "#292929",
    },
    subHeading3: {
      fontFamily: "Mukta Mahee",
      // fontSize: "0.75rem",
      fontWeight: 700,
      color: "#292929",
    },
    caption1: {
      fontFamily: "Mukta Mahee",
      fontSize: "12px",
      fontWeight: 400,
      color: "#393939",
    },
  },
});

export default theme;
