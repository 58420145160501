import React from "react";

import medicalConditionsModel from "./medical-conditions-model";
import EntityTable from "../entities/entity-table";

export default function MedicalConditionsTable(props) {
  return (
    <EntityTable
      entityName="medicalConditions"
      entityModel={medicalConditionsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
