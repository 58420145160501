/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import QRCode from "qrcode.react";

import { Typography, Box, Grid, Snackbar } from "@mui/material";
import useEntity from "../entities/use-entity";

const QRTable = () => {
  const { loading, loadingMessage, getEntities } = useEntity();
  const [rowData, setRowData] = useState([]);
  const entityName = "ingredients";
  useEffect(() => {
    getEntities(entityName, setRowData);
    return () => {
      setRowData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Downlaod function for QR codes
  const downloadQRCode = (canvasId, qrValue) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById(canvasId);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}_${canvasId}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //coloun values for QR code Table
  const column = [
    { field: "id", headerName: "QR Values", width: 200 },
    { field: "name", headerName: "Ingredients Name", width: 150 },
    {
      field: "qrCode",
      headerName: "Download",
      width: 200,
      renderCell: (params) => (
        <div>
          <QRCode // QRCode is a tag responsible for generating QR Code
            id={`qrCode-${params.row.id}`} // Unique ID for each QR code
            value={params.row.id} // Assuming the QR value is the ID
            size={250}
            level={"H"}
            includeMargin={true}
            style={{ display: "none" }}
          />
          <button
            type="button"
            onClick={() =>
              downloadQRCode(`qrCode-${params.row.id}`, params.row.name)
            }
          >
            Download QR Code
          </button>
        </div>
      ),
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = (newSelection) => {
    if (newSelection.length > 0) {
      setSelectedRows(newSelection);
    }
  };

  return (
    <Box padding={2} style={{ height: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">QR Codes</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              rows={rowData}
              columns={column}
              pageSize={50}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(details) =>
                handleSelectionChange(details)
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QRTable;
