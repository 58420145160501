import React from "react";

import productsModel from "./products-model";
import EntityTable from "../entities/entity-table";

export default function ProductsTable(props) {
  return (
    <EntityTable
      entityName="products"
      entityModel={productsModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
