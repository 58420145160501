import React from "react";

import productReleasesModel from "./product-releases-model";
import EntityTable from "../entities/entity-table";

export default function ProductReleasesTable(props) {
  return (
    <EntityTable
      entityName="productReleases"
      entityModel={productReleasesModel}
      canEdit={true}
      canDelete={true}
    />
  );
}
