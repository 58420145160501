import { Box, Grid, styled } from "@mui/material";

export const BottomBorder = styled(Box)({
  marginTop: "5px",
  width: 150,
  height: "4px",
  background: "#EF4823",
  borderRadius: "5px 5px 0px 0px",
});
export const TabContainer = styled(Grid)({
  borderRadius: "22px",
  marginTop: "32px",
  display: "flex",
  alignItems: "stretch",
  justifyContent: " space-around",
  height: 40,
});
export const SingleTabContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
});
