import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

var firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true });

const storage = firebase.storage();
const storageRef = firebase.storage().ref();

const FirebaseApi = {
  cuisines: firestore.collection("/cuisines"),
  courses: firestore.collection("/courses"),
  tags: firestore.collection("/tags"),

  diets: firestore.collection("/diets", { merge: true }),
  measurementUnit: firestore.collection("/measurementUnits"),

  cookingMethods: firestore.collection("/cookingMethods"),
  tool: firestore.collection("/tools"),

  recipes: firestore.collection("recipes"),
  testRecipes: firestore.collection("testRecipes2", { merge: true }),
  testRecipesGoutham: firestore.collection("testRecipesGoutham", {
    merge: true,
  }),

  recipeCategory: firestore.collection("/recipeCategories"),
  recipeState: firestore.collection("/recipeStates"),

  ingredientCategories: firestore.collection("ingredientCategories"),
  foodCategories: firestore.collection("ingredientCategories"),
  ingredientState: firestore.collection("ingredientStates"),

  drafts: firestore.collection("drafts"),

  actions: firestore.collection("/actions"),
  tools: firestore.collection("/tools"),
  // spices: firestore.collection("/spices"),
  // cleaned: firestore.collection("cleaned"),
  // trash: firestore.collection("trash"),

  // new added entities
  // pure
  toolCategories: firestore.collection("toolCategories"),

  units: firestore.collection("units"),
  prepStyles: firestore.collection("prepStyles"),
  conversions: firestore.collection("conversions"),
  ingredientType: firestore.collection("ingredientType"),

  cookingCategory: firestore.collection("cookingCategory"),

  // dependent
  allergies: firestore.collection("allergies"),
  medicalConditions: firestore.collection("medicalConditions"),
  cookingPresets: firestore.collection("presets"),

  humanScale: firestore.collection("humanScales"),
  ingredients: firestore.collection("ingredients"),

  // semiTest
  semiTest: firestore.collection("semiTest"),

  // ContentCreator -
  articles: firestore.collection("articles"),
  creatorMemberships: firestore.collection("creatorMemberships"),
  creatorEarnings: firestore.collection("creatorEarnings"),
  creatorUILayouts: firestore.collection("creatorUILayouts"),
  user: firestore.collection("users"),
  //users: firestore.collection('users'),
  creatorSubscribers: firestore.collection("creatorSubscribers"),
  creatorBillings: firestore.collection("creatorBillings"),
  creatorCollections: firestore.collection("creatorCollections"),
  articleDrafts: firestore.collection("articleDrafts"),

  products: firestore.collection("products"),
  productReleases: firestore.collection("productReleases"),

  creatorLeads: firestore.collection("creatorLeads"),

  aisles: firestore.collection("aisles"),
  featuredCookbooks: firestore.collection("featuredCookbooks"),
  defaultAssets: firestore.collection("defaultAssets"),

  timestamp: firebase.firestore.FieldValue.serverTimestamp,

  // individual recipe format
  newDefinedRecipes: firestore.collection("newDefinedRecipes", { merge: true }),
  recipeIngredients: firestore.collection("recipeIngredients", {
    merge: true,
  }),
  recipeSections: firestore.collection("recipeSections", {
    merge: true,
  }),
  recipeInstructions: firestore.collection("recipeInstructions", {
    merge: true,
  }),
  cookingParameters: firestore.collection("cookingParameters", {
    merge: true,
  }),

  controlModes: firestore.collection("controlModes", { merge: true }),
  fdcFoods: firestore.collection("fdcFoods", { merge: true }),
  recipeCards: firestore.collection("recipeCards", { merge: true }),
  recipeFilters: firestore.collection("recipeFilters", { merge: true }),
  recipeFilterGroups: firestore.collection("recipeFilterGroups", {
    merge: true,
  }),
  userAccess: firestore.collection("userAccess", {
    merge: true,
  }),
  userLogs: firestore.collection("userLogs", {
    merge: true,
  }),
  deletedRecipes: firestore.collection("deletedRecipes", {
    merge: true,
  }),
};

const auth = () => {
  return firebase.auth();
};

export default firebase;
export { firestore, FirebaseApi, storage, auth, storageRef };
