import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import IngredientPlaceholder from "../assets/ingredient-placeholder1.png";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  maxHeight: 532,
  minHeight: 232,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 10px",
  overflowY: "auto",
};

export default function ReorderIngredientsModal(props) {
  const { openReorderPopup, setOpenReorderPopup } = props;
  const { setValue, getValues } = useFormContext();
  const recipeIngredients = getValues("recipeIngredients") || [];
  const recipeSections = getValues("recipeSections");

  const [draggedItem, setDraggedItem] = useState(null);
  const [localIngredients, setLocalIngredients] = useState([
    ...recipeIngredients,
  ]);

  // Synchronising the state

  useEffect(() => {
    setLocalIngredients([...recipeIngredients]);
  }, [recipeIngredients]);

  const handleDragStart = (e, item) => {
    if (item?.instructionId) {
      toast.error("This ingredient has already been used in a step.");
      e.preventDefault();
    }
    setDraggedItem(item);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, recipeIngredients, sectionID) => {
    if (draggedItem?.instructionId) {
      toast.error("This ingredient has already been used in a step.", {
        duration: 2000,
      });
      e.preventDefault();
    } else {
      const updatedItems = localIngredients?.map((item) => {
        if (item.id === draggedItem.id) {
          return {
            ...item,
            sectionId: sectionID,
            instructionId: "",
          };
        }
        return item;
      });
      setLocalIngredients(updatedItems);
    }
  };

  const onSaveHandler = () => {
    setValue("recipeIngredients", localIngredients);
    setOpenReorderPopup(false);
  };

  const onCloseHandler = () => {
    setLocalIngredients([...recipeIngredients]);
    setOpenReorderPopup(false);
  };
  return (
    <Modal
      open={openReorderPopup}
      onClose={onCloseHandler}
      aria-labelledby="modal-modal-title"
      s
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              xs={12}
              container
              justifyContent="space-between"
              // border="1px solid black"
              background="#fff"
            >
              <Grid item>
                {" "}
                <Typography
                  variant="h3"
                  sx={{ fontSize: "16px", color: "#000" }}
                >
                  Reordering Ingredients
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    color: "#969696",
                    fontWeight: 500,
                    marginTop: "6px",
                  }}
                >
                  Drag and drop within any section you like
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSaveHandler}
                  sx={{
                    background:
                      " linear-gradient(90deg, #EF4823 0%, #F47422 100%)",
                    padding: " 8px 16px",
                  }}
                >
                  <Typography
                    variant="body3"
                    sx={{ textTransform: "none", color: "#fff" }}
                  >
                    Save Changes
                  </Typography>
                </Button>{" "}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item> */}
          <Box
            sx={{
              marginTop: "19px",
              width: "100%",
              height: "100%",
              "&::-webkit-scrollbar": {
                display: "none" /* WebKit */,
              },
            }}
          >
            {recipeSections.map((item, index) => (
              <Box
                onDrop={(e) => handleDrop(e, item, item.id)}
                onDragOver={handleDragOver}
                sx={{
                  // height: "142px",
                  padding: "16px",
                  background: "#F7F7F7",
                  marginY: 1,
                  borderRadius: "8px",
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#7C7B79",
                      marginBottom: "12px",
                      textTransform: "capitalize",
                    }}
                  >
                    {`Section  ${
                      index + 1 <= 9 ? `0${index + 1}` : index + 1
                    }  : ${item.sectionName || item.title}`}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="160px"
                    alignItems="center"
                  />
                </Box>

                <Box
                  display="flex"
                  sx={{
                    maxWidth: "100%",
                    overflowX: "scroll",
                    "&::-webkit-scrollbar": {
                      display: "none" /* WebKit */,
                    },
                  }}
                >
                  {localIngredients
                    ?.filter((ing) => ing.sectionId === item.id)
                    .map((ingredient) => (
                      <Box
                        onDragStart={(e) => handleDragStart(e, ingredient)}
                        onDragEnter={(e) => handleDragEnter(e, ingredient)}
                        onDragOver={handleDragOver}
                        mr={2}
                        sx={{
                          // height: 82,
                          width: 58,
                        }}
                      >
                        <Box
                          sx={{
                            borderRadius: "50%",
                            // padding: "5px",
                            height: 48,
                            width: 48,
                            position: "relative",
                            background: "#fff",
                          }}
                        >
                          <img
                            src={
                              ingredient?.ingredientImage ||
                              IngredientPlaceholder
                            }
                            onError={(e) =>
                              (e.target.src = IngredientPlaceholder)
                            }
                            style={{
                              height: 48,
                              width: 48,
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          mt={1}
                          sx={{ fontSize: "12px", textAlign: "center" }}
                        >
                          {ingredient?.name || "Ingredient Name"}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
        {/* </Grid> */}
      </Box>
    </Modal>
  );
}
