import React from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import PopupImage from "../assets/caution-icon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  // height: 265,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 0px",
};

export default function DeleteStepModal(props) {
  const {
    openDeleteStepPopup,
    setOpenDeleteStepPopup,
    instructionData,
    setOpenDotMenu,
    removeRecipeInstruction,
    filterRecipeInstructions,
  } = props;

  const indexOfInstruction = filterRecipeInstructions?.findIndex(
    (i) => i.id === instructionData?.id
  );

  const index =
    indexOfInstruction + 1 <= 9
      ? `0${indexOfInstruction + 1}`
      : `${indexOfInstruction + 1}`;

  return (
    <Modal
      open={openDeleteStepPopup}
      // onClose={() => setOpenConvertToProModel(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          xs={12}
          container
          flexDirection="column"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item>
            <img src={PopupImage} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontSize: "20px" }}>
              Do you want to delete Step {index}?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body3" sx={{ lineHeight: "22px" }}>
              You are about to delete Step {index},
              <q>{instructionData?.title}</q> This action is non reversible and
              will delete all the information written within this step. Are you
              sure you want to proceed?
            </Typography>
          </Grid>
        </Grid>
        <Grid width="100%" display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => {
              setOpenDeleteStepPopup(false);
              setOpenDotMenu(false);
            }}
          >
            <Typography variant="body3" sx={{ textTransform: "none" }}>
              Go,back
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => {
              removeRecipeInstruction(
                instructionData.sectionId,
                instructionData.id
              );
              setOpenDeleteStepPopup(false);
              setOpenDotMenu(false);
            }}
          >
            <Typography
              variant="body3"
              sx={{ textTransform: "none", color: "#fff" }}
            >
              Yes, Delete Step
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
