const presets = {
  name: "Presets",
  model: "presets",
  collectionName: "cookingPresets",
  defaultObject: {
    // name: "",
    // type: "",
    // description: "",
    // thumbnail: "",
    // controlType: "",
    // temperature: "",
    // power: "",
    // duration: "",
    // active: true,
    // cookingAction: {},

    id: "",
    isKlynkPreset: true,
    userId: "",
    actionId: "",
    actionName: "",
    connectedSemi: {},
    primaryName: "",
    secondaryName: "",
    controlModeId: "",
    controlModeName: "",
    externalTemperatureInC: "",
    centralTemperatureInC: "",
    timeInMinuites: "",
    powerInLevel: "",
    externalProbeEnabled: false,
    externalProbeControl: false,
    compatibleDevices: [],
    version: "",
  },
  fields: [
    // {
    //   name: "thumbnail",
    //   dataType: "String",
    //   inputFieldType: "ImageUploader",
    //   label: "Thumbnail",
    //   required: false,
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    //   showInListLayout: true,
    //   listLayoutWidth: 125,
    // },
    {
      name: "primaryName",
      dataType: "String",
      inputFieldType: "text",
      label: "Primary Name",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
      required: true,
    },
    {
      name: "secondaryName",
      dataType: "String",
      inputFieldType: "text",
      label: "Secondary Name",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
      required: true,
    },
    {
      name: "actionName",
      dataType: "String",
      inputFieldType: "select-from-collection",
      label: "Action Name",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
      collectionName: "actions",
      keyInCollection: "name",
    },
    {
      name: "actionId",
      dataType: "String",
      inputFieldType: "autoupdate",
      label: "Action ID",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 6,
      derived: true,
      derivedFrom: "actionName",
      collectionName: "actions",
      collectionLookupKey: "name",
      collectionReturnKey: "id",
    },
    {
      name: "isKlynkPreset",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Klynk?",
      defaultValue: true,
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "controlModeName",
      dataType: "String",
      inputFieldType: "select-from-collection",
      label: "CTRL-Name",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
      collectionName: "controlModes",
      keyInCollection: "firmwareName",
    },
    {
      name: "controlModeId",
      dataType: "String",
      inputFieldType: "text",
      label: "CTRL-ID",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "controlModeFirmwareId",
      dataType: "String",
      inputFieldType: "text",
      label: "FirmWare-ID",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "centralTemperatureInC",
      dataType: "Number",
      inputFieldType: "text",
      label: "CTemp(C)",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "timeInMinuites",
      dataType: "Number",
      inputFieldType: "text",
      label: "Time(min)",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "powerInLevel",
      dataType: "Number",
      inputFieldType: "text",
      label: "Power(1-12)",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "externalTemperatureInC",
      dataType: "Number",
      inputFieldType: "text",
      label: "EPTemp.(C)",
      showInListLayout: true,
      listLayoutWidth: 75,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "externalProbeEnabled",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "External Probe?",
      defaultValue: false,
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "externalProbeControl",
      dataType: "String",
      inputFieldType: "select",
      options: ["temperature", "time", "power"],
      label: "External Probe Control",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 3,
    },
    {
      name: "compatibleDevices",
      dataType: "String",
      inputFieldType: "text",
      label: "Compatible Devices",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "version",
      dataType: "String",
      inputFieldType: "text",
      label: "Version",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "type",
      dataType: "String",
      inputFieldType: "select",
      options: ["preset"],
      label: "Type",
      defaultValue: "preset",
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
    {
      name: "active",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Active ? (show in UI on /)",
      defaultValue: true,
      showInListLayout: true,
      listLayoutWidth: 125,
      showInEditLayout: true,
      editLayoutWidth: 6,
    },
  ],
  layout: [
    [{ name: "primaryName", width: 6 }],
    [{ name: "secondaryName", width: 6 }],
    [
      { name: "actionName", width: 6 },
      { name: "actionId", width: 6 },
    ],

    [{ name: "isKlynkPreset", width: 6 }],
    [{ name: "controlModeName", width: 6 }],
    [
      { name: "centralTemperatureInC", width: 4 },
      { name: "timeInMinuites", width: 4 },
      { name: "powerInLevel", width: 4 },
    ],
    [
      { name: "externalTemperatureInC", width: 6 },
      { name: "externalProbeEnabled", width: 6 },
    ],
    [{ name: "externalProbeControl", width: 6 }],
    [{ name: "compatibleDevices", width: 6 }],
    [{ name: "version", width: 6 }],
    [{ name: "thumbnail", width: 6 }],
  ],
  actions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default presets;
