import React from "react";
import { useLogsHook } from "./use-logs-hook";
const { formatTimestamp } = useLogsHook();

export const columns = [
  {
    field: "srNo",
    headerName: "Sr No",
    width: 50,
    valueGetter: (params) => `${params.api.getRowIndex(params.id) + 1}`,
  },
  {
    field: "userName",
    headerName: "Name",
    width: 200,
    type: "string",
  },
  {
    field: "createdAt",
    headerName: "Log time",
    width: 200,
    type: "date",
    renderCell: (props) => <span>{formatTimestamp(props.row?.createdAt)}</span>,
    sortComparator: (date1, date2) => new Date(date1) - new Date(date2),
  },
  {
    field: "module",
    headerName: "Module",
    width: 180,
    type: "string",
  },
  {
    field: "recipeName",
    headerName: "Recipe Name",
    width: 200,
    type: "string",
  },
  {
    field: "recipeID",
    headerName: "Recipe ID",
    width: 180,
    type: "string",
  },
  {
    field: "operationType",
    headerName: "Operation Type",
    width: 160,
    type: "string",
  },
  {
    field: "diiferenceOfRecord",
    headerName: "Diiference Of Record",
    width: 160,
    type: "string",
  },
  {
    field: "newRecord",
    headerName: "New Record",
    width: 220,
    type: "string",
  },
];
