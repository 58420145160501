import { useFormContext } from "react-hook-form";
import useRecipeHook from "../use-recipe-hook";
import { cookingParameterConstant } from "../constants";

const useRecipeInstructions = (props) => {
  const { setSelectedInstructionArrayIndex, handlingInstructionId } = props;
  const { getValues, setValue } = useFormContext();
  const { generationOfNewUuid } = useRecipeHook();

  const recipeInstructions = getValues("recipeInstructions");
  const cookingParameters = getValues("cookingParameters");
  const recipeIngredients = getValues("recipeIngredients");
  const {
    presetId,
    presetName,
    controlModeId,
    controlModeFirmwareName,
    controlModeFirmwareId,
    semiScreenId,
    isCentralProbeTemperatureRequired,
    isExternalProbeTemperatureRequired,
    isPowerRequired,
    isTimeRequired,
  } = cookingParameterConstant;

  // Function to add a new recipe instruction
  const addRecipeInstruction = (sectionId) => {
    const newInstructionId = generationOfNewUuid();
    const newInstructionIndex =
      recipeInstructions?.filter(
        (instruction) => instruction?.sectionId === sectionId
      )?.length ?? 0;
    const instructionLength = recipeInstructions.length - 1;

    // Creating new instruction and cooking parameter objects
    const newRecipeInstruction = {
      "@type": "HowToStep",
      id: newInstructionId,
      mentionsTitle: "",
      recipeId: getValues("metadata").recipeId,
      sectionId: sectionId || null,
      tip: "",
      title: "",
      tools: [],
      instructionIndex: newInstructionIndex + 1,
      stepImageUrl: "",
      stepVideoUrl: "",
      derivedIngredients: [],
    };

    const newCookingParameter = {
      id: generationOfNewUuid(),
      sectionId: sectionId || null,
      instructionId: newInstructionId || null,
      recipeId: getValues("metadata").recipeId,
      instructionIndex: newInstructionIndex + 1,
      stirring: "",
      isLidOpen: false,
      action: "",
      actionId: "",
      powerInLevel: null,
      centralTemperatureInC: null,
      externalTemperatureInC: null,
      durationInMins: null,
      tempSensor: "",
      manualHeatLevel: "",
      presetId,
      presetName,
      controlModeId,
      controlModeFirmwareName,
      controlModeFirmwareId,
      semiScreenId,
      isCentralProbeTemperatureRequired,
      isExternalProbeTemperatureRequired,
      isPowerRequired,
      isTimeRequired,
    };

    // Updating form values with new instruction and cooking parameter
    setValue("recipeInstructions", [
      ...recipeInstructions,
      newRecipeInstruction,
    ]);
    setValue("cookingParameters", [...cookingParameters, newCookingParameter]);
    setSelectedInstructionArrayIndex(instructionLength + 1);
    handlingInstructionId(newInstructionId);
  };

  const addInBetweenRecipeInstruction = (sectionId, stepIndex) => {
    const newInstructionId = generationOfNewUuid();
    const newRecipeInstruction = {
      "@type": "HowToStep",
      id: newInstructionId,
      mentionsTitle: "",
      recipeId: getValues("metadata").recipeId,
      sectionId: sectionId || null,
      tip: "",
      title: "",
      tools: [],
      instructionIndex: stepIndex + 2,
      stepImageUrl: "",
      stepVideoUrl: "",
      derivedIngredients: [],
    };

    const newCookingParameter = {
      id: generationOfNewUuid(),
      sectionId: sectionId || null,
      instructionId: newInstructionId || null,
      recipeId: getValues("metadata").recipeId,
      instructionIndex: stepIndex + 2,
      stirring: "",
      isLidOpen: false,
      action: "",
      actionId: "",
      powerInLevel: null,
      centralTemperatureInC: null,
      externalTemperatureInC: null,
      durationInMins: null,
      tempSensor: "",
      manualHeatLevel: "",
      presetId,
      presetName,
      controlModeId,
      controlModeFirmwareName,
      controlModeFirmwareId,
      semiScreenId,
      isCentralProbeTemperatureRequired,
      isExternalProbeTemperatureRequired,
      isPowerRequired,
      isTimeRequired,
    };

    const updateItems = (items, newItem) => {
      const filteredItems = items.filter(
        (item) => item.sectionId === sectionId
      );
      filteredItems.splice(stepIndex + 1, 0, newItem);
      return filteredItems.map((item, index) => ({
        ...item,
        instructionIndex: index + 1,
      }));
    };

    const updatedRecipeInstructions = updateItems(
      recipeInstructions,
      newRecipeInstruction
    );
    const updatedCookingParameters = updateItems(
      cookingParameters,
      newCookingParameter
    );

    setValue("recipeInstructions", [
      ...recipeInstructions.filter((item) => item.sectionId !== sectionId),
      ...updatedRecipeInstructions,
    ]);

    setValue("cookingParameters", [
      ...cookingParameters.filter((item) => item.sectionId !== sectionId),
      ...updatedCookingParameters,
    ]);

    handlingInstructionId(newInstructionId);
    setSelectedInstructionArrayIndex(stepIndex + 1);
  };

  const handleInstructionChangeAfterDelete = (
    sectionId,
    deletedSectionIndex
  ) => {
    let recipeInstructionPerSection = recipeInstructions?.filter(
      (item) => item.sectionId === sectionId
    );
    const targetIndex =
      deletedSectionIndex === 0
        ? deletedSectionIndex + 1
        : deletedSectionIndex - 1;
    handlingInstructionId(recipeInstructionPerSection[targetIndex]?.id, 0);
  };
  const decrementInstructionIndex = (filteredArray, deletedSectionIndex) => {
    const updatedRecipeInstructionPerSection = filteredArray.map((item) => {
      if (item.instructionIndex > deletedSectionIndex) {
        return {
          ...item,
          instructionIndex: item.instructionIndex - 1,
        };
      } else {
        return item;
      }
    });

    return updatedRecipeInstructionPerSection;
  };

  const removeInstructionIds = (instructionId) => {
    const removedInstructionId = recipeIngredients.map((item) => {
      if (item.instructionId === instructionId) {
        return {
          ...item,
          instructionId: "",
        };
      } else {
        return item;
      }
    });
    setValue("recipeIngredients", removedInstructionId);
  };
  // Function to remove a recipe instruction
  const removeRecipeInstruction = (sectionId, instructionId) => {
    setSelectedInstructionArrayIndex((prev) => prev - 1);
    // Filtering out the instruction and cooking parameter to be removed
    let recipeInstructionPerSection = recipeInstructions?.filter(
      (item) => item.sectionId === sectionId
    );
    let recipeInstructionNotPerSection = recipeInstructions?.filter(
      (item) => item.sectionId !== sectionId
    );
    let recipeCookingParametersPerSection = cookingParameters?.filter(
      (item) => item.sectionId === sectionId
    );
    let recipeCookingParametersNotPerSection = cookingParameters?.filter(
      (item) => item.sectionId !== sectionId
    );
    const recipeInstructionIndex = recipeInstructionPerSection?.findIndex(
      (item) => item.id === instructionId
    );
    handleInstructionChangeAfterDelete(sectionId, recipeInstructionIndex);

    const updatedRecipeInstructions = recipeInstructionPerSection.filter(
      (instruction) => instruction.id !== instructionId
    );
    const updatedCookingParameters = recipeCookingParametersPerSection.filter(
      (cookingParameter) => cookingParameter.instructionId !== instructionId
    );
    const updatedRecipeInstructionsDec = decrementInstructionIndex(
      updatedRecipeInstructions,
      recipeInstructionIndex
    );

    const updatedCookingParametersDec = decrementInstructionIndex(
      updatedCookingParameters,
      recipeInstructionIndex
    );

    // Updating form values with updated instructions and parameters
    setValue("recipeInstructions", [
      ...updatedRecipeInstructionsDec,
      ...recipeInstructionNotPerSection,
    ]);
    setValue("cookingParameters", [
      ...updatedCookingParametersDec,
      ...recipeCookingParametersNotPerSection,
    ]);
    removeInstructionIds(instructionId);
  };

  return {
    // instruction function
    addRecipeInstruction,
    addInBetweenRecipeInstruction,
    removeRecipeInstruction,
  };
};

export default useRecipeInstructions;
