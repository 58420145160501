const mentionsStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: "16px",
    fontWeight: 400,
  },

  "&multiLine": {
    control: {
      fontFamily: "Mukta Mahee",
      fontSize: "16px",

      minHeight: 63,
    },
    highlighter: {
      color: "#EF4823",
      padding: 9,
      // border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "1px solid silver",
      wordBreak: "break-all",
      // color: "black",
    },
  },

  ".at-tag": {
    // Define styles specific to @ tag
    color: "blue", // Change to your desired style
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
      padding: "12px",
      fontSize: 14,
      height: 131,
      width: 171,
      overflowY: "scroll",
      marginTop: "10px",
    },
    item: {
      padding: "5px 15px",
      fontFamily: "Mukta Mahee",
      "&focused": {
        backgroundColor: "#FFD2C3",
        borderRadius: "4px",
      },
    },
  },
};

export default mentionsStyle;
