/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { nameValidation } from "../units/units-model";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
import { string } from "yup";

// CSS styles moved from inline to a central object
const styles = {
  listLayoutComponent: {
    maxWidth: "100%",
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  thumbnailImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  ingredientName: {
    marginRight: "8px",
    textTransform: "capitalize",
  },
};

const allergies = {
  name: "Allergies",
  model: "allergies",
  collectionName: "allergies",
  defaultObject: {
    id: null,
    thumbnailUrl: null,
    name: null,
    description: null,
    ingredients: [],
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: false,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={styles.thumbnailImage}
          />
        );
      },
      validation: string().required("Thumbnail is required"),
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div style={styles.listLayoutComponent}>
            <span style={styles.capitalize}>{name}</span>
          </div>
        );
      },
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div style={styles.listLayoutComponent}>
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "ingredients",
      dataType: "Array",
      inputFieldType: "notRequired-autocomplete",
      label: "Ingredients",
      showInListLayout: true,
      listLayoutWidth: 450,
      showInEditLayout: true,
      required: false,
      multiple: true,
      editLayoutWidth: 6,
      collectionName: "allergies",
      keyInCollection: "name",
      listLayoutComponent: (props) => {
        return (
          <div style={styles.listLayoutComponent}>
            {props.row?.ingredients?.map((ingredient, index) => (
              <span key={ingredient.id} style={styles.ingredientName}>
                {ingredient.name}
                {index < props.row.ingredients.length - 1 && ", "}
              </span>
            ))}
          </div>
        );
      },
    },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "name", width: 6 }],
    [{ name: "description", width: 6 }],
    [{ name: "ingredients", width: 6 }],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
  ],
};

export default allergies;
