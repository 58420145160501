/* eslint-disable no-unused-vars */
// A hook to expose a global logger module that can be used anywhere in the app

import { createLogger, format, transports, addColors, label } from "winston";

const { combine, timestamp, printf } = format;

const myFormat = printf(({ level, message, timestamp }) => {
  return `${timestamp} ${level}: ${message}`;
});

const Logger = createLogger({
  level: "info",
  format: combine(
    format.label({ label: "right meow!" }),
    format.colorize(),
    format.simple(),
    timestamp(),
    myFormat
  ),
  transports: [new transports.Console()],
});

export default Logger;
