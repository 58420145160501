/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { FirebaseApi } from "../../config/firebase-config";

export default function () {
  const [command, setCommand] = useState("");
  const [commands, setCommands] = useState([]);
  useEffect(() => {
    getCommands();
  }, []);

  const getCommands = async () => {
    try {
      FirebaseApi["semiTest"].onSnapshot((snapShot) => {
        const items = snapShot.docs.map((item) => {
          return { id: item.id, ...item.data() };
        });

        setCommands(items);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveCommand = async () => {
    try {
      const result = await FirebaseApi["semiTest"].add({ command });

      if (result) {
        setCommand("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCommand = async (id) => {
    try {
      await FirebaseApi["semiTest"].doc(id).delete();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box padding={2}>
      <Grid container alignItems="center" height={"100%"}>
        <Grid item xs={12}>
          <Grid
            container
            padding={2}
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
          >
            <TextField
              placeholder="command"
              value={command}
              onChange={(e) => setCommand(e.target.value)}
            />

            <Button
              onClick={saveCommand}
              variant="contained"
              style={{ margin: "10px 0" }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ border: "1px solid black" }}>
          {commands.length > 0 &&
            commands.map((cmd, index) => (
              <Box
                style={{
                  padding: 10,
                  borderBottom:
                    index != commands.length - 1 ? "1px solid black" : "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box style={{ flex: 1 }}>
                  <Typography>{cmd.command}</Typography>
                </Box>
                <Button onClick={() => deleteCommand(cmd.id)}>Delete</Button>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
}
