/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";

import recipeFilterGroupsModel from "./recipe-filter-groups-model";
import EntityTable from "../entities/entity-table";

const RecipeFilterGroupsTable = (props) => {
  return (
    <EntityTable
      entityName="recipeFilterGroups"
      entityModel={recipeFilterGroupsModel}
      canEdit={true}
      canDelete={true}
    />
  );
};

export default RecipeFilterGroupsTable;
