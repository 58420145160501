/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { Box, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import dummyImage from "../../.././src/assets/Undetected-ingredient-image.png";
import { thumbnailUrlValidation } from "../ingredients/ingredients-model";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

export const titlesValidation = string()
  .required("Required")
  .matches(/^[a-zA-Z0-9\s]+$/, {
    message: "Only Aplhanumeric are allowed",
    excludeEmptyString: true,
  })
  .max(60, "Maximum 60 characters are allowed");

  export const recipeListValidation = Yup.array()
  .of(
    Yup.object().shape({
      id: Yup.string().required(),
      title: Yup.string().required(),
    })
  )
  .min(1, "At least one recipe is required")
  .required("Required");

const recipeFiltersModel = {
  name: "Recipe Filters",
  model: "recipeFilters",
  collectionName: "recipeFilters",
  defaultObject: {
    id: null,
    title: null,
    subTitle: null,
    thumbnailUrl: null,
    isActive: true,
    filterLayoutStyle: "recipe-card-collection",
    recipeList: [],
    filterByKey: null,
    filterByValue: null,
    sortByKey: "createdAt",
    sortOrder: "asc",
    createdAt: null,
    updatedAt: null,
  },
  fields: [
    {
      name: "thumbnailUrl",
      dataType: "String",
      inputFieldType: "image-uploader",
      label: "Thumbnail",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 75,
      validation: thumbnailUrlValidation,
      listLayoutComponent: (props) => {
        const thumbnailUrl = props.row?.thumbnailUrl || dummyImage;
        return (
          <img
            src={thumbnailUrl}
            alt={props.row?.name || "Placeholder"}
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
          />
        );
      },
    },    
    {
      name: "title",
      dataType: "String",
      inputFieldType: "text",
      label: "Title",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: titlesValidation,
      listLayoutComponent: (props) => {
        const title = props.row?.title;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{title}</span>
          </div>
        );
      },
    },
    {
      name: "subTitle",
      dataType: "String",
      inputFieldType: "subtitle-text",
      label: "Sub Title",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: titlesValidation,
      listLayoutComponent: (props) => {
        const subTitle = props.row?.subTitle;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{subTitle}</span>
          </div>
        );
      },
    },
    {
      name: "filterByKey",
      dataType: "String",
      inputFieldType: "notRequired-select",
      label: "Filter Key (for Typesense)",
      required: true,
      options: [
        "recipeIngredients",
        "smartFilters",
        "tags",
        "diets",
        "cuisines",
        "courses",
      ],
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      collectionName: "cookBooks",
      listLayoutWidth: 150,
    },
    {
      name: "filterByValue",
      dataType: "String",
      inputFieldType: "simple-autoupdate",
      label: "Filter Value (for Typesense)",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      derivedFrom: "title",
      listLayoutWidth: 150,
      listLayoutComponent: (props) => {
        const filterByValue = props.row?.filterByValue;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{filterByValue}</span>
          </div>
        );
      },
    },
    {
      name: "sortByKey",
      dataType: "String",
      inputFieldType: "notRequired-select",
      label: "Sort By Key",
      required: true,
      options: ["alphabetical", "createdAt", "updatedAt"],
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
    {
      name: "sortOrder",
      dataType: "String",
      inputFieldType: "notRequired-select",
      label: "Sort Order",
      required: true,
      options: ["asc", "desc"],
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
    // {
    //   name: "filterLayoutStyle",
    //   dataType: "String",
    //   inputFieldType: "select",
    //   label: "Filter Layout",
    //   required: true,
    //   options: [
    //     "thumbnail-and-text-vertical-with-background",
    //     "thumbnail-and-text-horizontal-with-background",
    //     "thumbnail-and-text-vertical-no-background",
    //     "text-with-background",
    //     "sorted-recipe-card-collection",
    //     "filtered-recipe-card-collection",
    //   ],
    //   showInEditLayout: true,
    //   editLayoutWidth: 6,
    //   showInListLayout: true,
    //   listLayoutWidth: 150,
    // },
    {
      name: "recipeList",
      dataType: "Array",
      inputFieldType: "autocomplete",
      label: "Recipes",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      multiple: true,
      collectionName: "recipeCards",
      validation: recipeListValidation,
      collectionLookupKey: "title",
      listLayoutComponent: (props) => {
        const recipeTitles = props.row?.recipeList.map((recipe) => recipe.title).join(", ");
        return <Typography variant="caption">{recipeTitles}</Typography>;
      },
      listLayoutWidth: 400,
    },    
    {
      name: "createdAt",
      dataType: "Timestamp",
      inputFieldType: "DateTimePicker",
      label: "Created At",
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: false,
    },
    {
      name: "updatedAt",
      dataType: "Timestamp",
      inputFieldType: "DateTimePicker",
      label: "Last Updated",
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: false,
    },
  ],
  layout: [
    [{ name: "thumbnailUrl", width: 12 }],
    [{ name: "title", width: 6 }],
    [{ name: "subTitle", width: 12 }],
    [
      { name: "filterByKey", width: 6 },
      { name: "filterByValue", width: 6 },
    ],
    [
      { name: "sortByKey", width: 6 },
      { name: "sortOrder", width: 6 },
    ],

    [{ name: "filterLayoutStyle", width: 12 }],
    [{ name: "recipeList", width: 12 }],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
    {
      name: "edit",
      label: "Edit",
      type: "edit",
      icon: "edit",
      color: "primary",
    },
    {
      name: "delete",
      label: "Delete",
      type: "delete",
      icon: "delete",
      color: "primary",
    },
  ],
};

export default recipeFiltersModel;
