import React from "react";
import { Select } from "@mui/material";
import { useController } from "react-hook-form";

const ModifiedSelect = (props) => {
  const { name, control, setValue } = props;
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const handleChange = (newValue) => {
    field.onChange(newValue.target.value);
    if (newValue.target.value === "hardware") {
      setValue("isRikuSelected", true);
      setValue("metadata.isRikuSelected", true);
      setValue("isSemiSelected", false);
      setValue("metadata.isSemiSelected", false);
    } else if (newValue.target.value === "manual") {
      setValue("isRikuSelected", false);
      setValue("metadata.isRikuSelected", false);
      setValue("isSemiSelected", true);
      setValue("metadata.isSemiSelected", true);
    }
  };

  return (
    <Select
      sx={{ ".MuiOutlinedInput-notchedOutline": { borderStyle: "none" } }}
      size={props.size ? props.size : "small"}
      fullWidth
      labelId={name}
      disableUnderline
      id={name}
      onChange={handleChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
    >
      {props.children}
    </Select>
  );
};

export default ModifiedSelect;
