const entityArrayName = [
  "recipeCards",
  "recipeIngredients",
  "recipeSections",
  "recipeInstructions",
  "cookingParameters",
];
const recipeCards = "recipeCards";
const units = "units";
const ingredients = "ingredients";
const prepStyles = "prepStyles";
const userAccessCollection = "userAccess";
const accessRoles = ["Admin", "Editor", "Viewer"];
const semiCompatible = ["Semi[EVT]", "Semi[DVT]", "semi"];
const rikuCompatible = ["Riku[EP1]"];
const userLogsCollection = "userLogs";
const deletedRecipes = "deletedRecipes";

export {
  entityArrayName,
  userAccessCollection,
  accessRoles,
  semiCompatible,
  rikuCompatible,
  userLogsCollection,
  deletedRecipes,
  recipeCards,
  units,
  ingredients,
  prepStyles,
};
