/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// This is the model json that will used by the form generator to generate the form
import React from "react";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";

const productReleases = {
  name: "Product Releases",
  model: "productReleases",
  collectionName: "productReleases",
  defaultObject: {
    id: null,
    name: null,
    releaseVersion: null,
    releaseDate: null,
    releaseNotes: null,
    releaseStatus: null,
    releaseType: null,
    product: null,
  },
  fields: [
    {
      name: "product",
      dataType: "Product",
      inputFieldType: "autocomplete",
      label: "Product",
      collectionName: "products",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required("Product is required"),
    },
    {
      name: "releaseVersion",
      dataType: "String",
      inputFieldType: "text",
      label: "Release Version",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: string().required(" Release Version is required"),
    },
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text",
      label: "Name",
      derived: true,
      derivedFrom: ["product", "releaseVersion"],
      derivedFunction: (product, releaseVersion) => {
        if (!product || !releaseVersion) {
          return "";
        }
        return product.name + "[" + releaseVersion + "]";
      },
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
    {
      name: "releaseDate",
      dataType: "String",
      inputFieldType: "text",
      label: "Release Date",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
    {
      name: "releaseNotes",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Release Notes",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
    },
  ],
  layout: [
    [{ name: "product", width: 6 }],
    [{ name: "releaseVersion", width: 6 }],
    [{ name: "name", width: 6 }],
    [{ name: "releaseDate", width: 6 }],
    [{ name: "releaseNotes", width: 6 }],
  ],
};

export default productReleases;
