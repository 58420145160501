import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const DeleteRecipeDialog = (props) => {
  const { isDelete, setIsDelete, deleteRecipes } = props;
  return (
    <Dialog open={isDelete} onClose={setIsDelete}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDelete(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={deleteRecipes} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecipeDialog;
