// ControlledAutoComplete is a component that allows the user to enter a list of options that they can choose from.
// It has an onChange prop that allows the user to change the value of the component, and a value prop that allows the user to set the value of the component.
// The component also has a getOptionLabel prop that allows the user to customize the label of the options that they can choose from.
// When the user selects an option, the component will call the onChange prop with the selected option as an argument, we will only save the id of the selected option.
import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";

const ControlledAutoCompleteArray = (props) => {
  const {
    control,
    name,
    options,
    label,
    multiple,
    collectionLookupKey = "name",
  } = props;
  const { field } = useController({
    name,
    control,
    //   rules: { required: true },
  });

  return (
    <Autocomplete
      size={props.size ? props.size : "small"}
      disablePortal
      label={label}
      id={field.name}
      onChange={(evt, newValue) => console.log("newValue", newValue)}
      onBlur={field.onBlur}
      // onChange={field.onChange}
      name={field.name}
      inputRef={field.ref}
      freeSolo
      options={options}
      value={field.value}
      multiple={multiple ? true : false}
      getOptionLabel={(option) =>
        option[collectionLookupKey] ? option[collectionLookupKey] : "N/A"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Choose from the list"
          fullWidth
          label={label}
          sx={{
            textTransform: "capitalize",
          }}
        />
      )}
    />
  );
};

export default ControlledAutoCompleteArray;
