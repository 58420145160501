import React, { useRef, useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { storage } from "../../../config/firebase-config";
import { v4 as uuidv4 } from "uuid";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
import circularSpinner from "../assets/circular-loader.gif";
import redWarning from "../assets/red-warning.png";
import { useFormContext } from "react-hook-form";
import { maxImageSizeInKB } from "../constants";
import {
  DeleteIconBox,
  EmptyImageBox,
  ImageBox,
  ImageUploaderContainerGrid,
  ReplaceIconBox,
} from "./styles";
import fileUpload from "../assets/file-upload.png";

export default function SingleImageUploader({
  storageLocation,
  name,
  imageUrl,
  errorMessage,
}) {
  const [uploadedImage, setUploadedImage] = useState("");
  const { setValue, setError, clearErrors } = useFormContext();
  const [loadingImage, setLoadingImage] = useState(false);
  const [uploadingError, setUploadingError] = useState(null);

  const fileInputRef = useRef(null);

  const onFileChange = (e) => {
    setLoadingImage(true);
    let storageLocationId = storageLocation.split("/");
    let file = e.target.files[0];
    if (file) {
      let uuid = uuidv4();
      const newImages = {
        file: file,
        fileName: file.name,
        status: "CREATED",
        storageRef: storage
          .ref()
          .child(
            storageLocation +
              "/original/" +
              storageLocationId[1] +
              "--" +
              uuid +
              "--" +
              file.name
          ),
        downloadURL: URL.createObjectURL(file),
        description: "",
      };

      setUploadedImage(newImages);
      e.target.value = null;
    }
  };

  const handleDelete = () => {
    setUploadedImage("");
    setValue(name, "");
    setLoadingImage(false);
  };

  const handleUpload = () => {
    if (uploadedImage) {
      if (uploadedImage.file.size > maxImageSizeInKB) {
        setLoadingImage(false);
        setError(name, {
          type: "custom",
          message: "Max allowed size is 10MB ",
        });
      } else {
        setLoadingImage(true);

        uploadedImage.storageRef.put(uploadedImage.file).on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            setUploadingError(error);
            console.log(error, "error");
          },
          () => {
            uploadedImage.storageRef.getDownloadURL().then((url) => {
              uploadedImage.downloadURL = url;
              setValue(name, url);
              uploadedImage.status = "UPLOADED";
              clearErrors(name);
              setUploadedImage(uploadedImage);
              setLoadingImage(false);
            });
          }
        );
      }
    }
  };

  useEffect(() => {
    if (loadingImage) {
      handleUpload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedImage]);
  const LoadingComponent = () => {
    return <img src={circularSpinner} width="130px" alt="circularSpinner" />;
  };
  const renderImageComponent = () => {
    if (loadingImage) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
            justifyContent: "flex-start",
            pt: 4,
            position: "relative",
          }}
        >
          <LoadingComponent />
          <Typography mb={1} mt={-2} variant="subtext2">
            Loading your image
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", color: "#7C7B79" }}
          >
            Supports JPG, JPEG, PNG{" "}
          </Typography>
        </EmptyImageBox>
      );
    }
    if (errorMessage || uploadingError) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
            position: "relative",
          }}
        >
          <img src={redWarning} height="20px" width="20px" alt="Warning" />
          <Typography my={1} variant="subtext2" color="red">
            {errorMessage || uploadingError}
          </Typography>
          <Typography variant="subtext2" sx={{ color: "#7C7B79" }}>
            Supports JPG, JPEG, PNG{" "}
          </Typography>
          <Typography
            onClick={() => {
              fileInputRef.current.click();
            }}
            variant="subtext2"
            sx={{ color: "#EF4823", cursor: "pointer" }}
          >
            {uploadingError ? "Try Again" : "Upload image"}
          </Typography>
        </EmptyImageBox>
      );
    }
    if (imageUrl) {
      return (
        <ImageBox>
          <img
            src={imageUrl}
            alt="profile"
            style={{
              display: "block",
              objectFit: "cover",
              width: "250px",
              height: "100%",
              borderRadius: "8px",
            }}
          />
          <DeleteIconBox className="delete-icon" onClick={handleDelete}>
            <ClearIcon sx={{ margin: "3px" }} />
          </DeleteIconBox>
          <ReplaceIconBox
            className="replace-icon"
            display="flex"
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <ReplayIcon sx={{ fontSize: "18px", marginTop: "3px" }} />
            <Typography variant="subtext2" ml={0.6}>
              Replace
            </Typography>
          </ReplaceIconBox>
        </ImageBox>
      );
    }
    if (!uploadedImage) {
      return (
        <EmptyImageBox
          sx={{
            border: errorMessage ? "2px dashed red" : "2px dashed #A7A7A7 ",
          }}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <img src={fileUpload} height="16px" width="16px" alt="Upload" />{" "}
          <Typography my={1} variant="subtext2">
            Choose Image to Upload{" "}
            {name === "metadata.thumbnailUrl" && (
              <span style={{ color: "#EF4823" }}>*</span>
            )}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", color: "#7C7B79" }}
          >
            Supports JPG, JPEG, PNG{" "}
          </Typography>
          <Typography my={1} variant="subtext2" sx={{ color: "#EF4823" }}>
            Max allowed size is 10MB
          </Typography>
        </EmptyImageBox>
      );
    }
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <ImageUploaderContainerGrid xs={3.4} item>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={onFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          {renderImageComponent()}
        </ImageUploaderContainerGrid>
      </Grid>
    </Box>
  );
}
