import React from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import PopupImage from "../assets/caution-icon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 398,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 20px 0px",
};

export default function DietSelectionPopup(props) {
  const { isDietSelection, setIsDietSelection, replaceDietsHandler, isVegTag } =
    props;

  return (
    <Modal
      open={isDietSelection}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          xs={12}
          container
          flexDirection="column"
          alignItems="center"
          rowSpacing={2}
        >
          <Grid item>
            <img src={PopupImage} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontSize: "20px" }}>
              Diet Selection Limit
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body3"
              sx={{ lineHeight: "22px", textAlign: "center" }}
            >
              {isVegTag
                ? "You've selected a vegetarian option. Non-vegetarian options cannot be selected at the same time. If you want to switch to non-vegetarian, please use the 'Replace'."
                : "You've selected a non-vegetarian option. Vegetarian options cannot be selected at the same time. If you want to switch to vegetarian, please use the 'Replace'."}
            </Typography>
          </Grid>
        </Grid>

        <Grid width="100%" display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            sx={{ width: "48%", height: "48px" }}
            onClick={() => setIsDietSelection(false)}
          >
            <Typography
              variant="body3"
              sx={{ textTransform: "none", color: "#EF4329" }}
            >
              cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{ width: "48%", height: "48px" }}
            onClick={replaceDietsHandler}
          >
            <Typography
              variant="body3"
              sx={{ textTransform: "none", color: "#fff" }}
            >
              Replace
            </Typography>
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
